import React, { useRef } from 'react'
import { useLocation, Link} from 'react-router-dom';
import "./LearningSphere.css"
import { learningBooks } from './LearningBooks';
function LearningSphere() {
  let match = useLocation()
  let bookProps = learningBooks.find(item => match.pathname.includes(item.route));

  const goToTag = (id : string) : void => {
    let element = document.getElementById(id)
    element?.scrollIntoView({ behavior: "smooth", block: "start", inline: "center" })
  }
  return (
    <div className="LearningSphere">
        <div className="LearningSphere-header">
            <Link to="/"><button className='LearningSphere-header-button'>&#8612; {bookProps?.title}</button></Link>
            {/* <div className="LearningSphere-header-percentage">0 / 100%</div> */}
            <div className="LearningSphere-header-date">{bookProps?.published}</div>
        </div>
        <div className='LearningSphere-navigation'>
          <ul className='LearningSphere-navigation-list'>
            {bookProps?.links.map(item => {
              return (
                <li key={item.title} onClick={() => goToTag(item.to.toLowerCase() + "-id")}>{item.title}</li>
              )
            })}
          </ul>
        </div>
        <div className='LearningSphere-body'>
          {bookProps?.book}
        </div>
    </div>
  )
}

export default LearningSphere;