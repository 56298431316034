export const libraries : Array<{
    title: string;
    link: string;
    description: string;
  }> =  [
    {
       title: "Atoms Canvas",
       link: "https://github.com/Skarvex/atoms-animated-background.git",
       description: "Interactive canvas background of dynamic atoms"
    }
]