import React from "react"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import MainTab from "./MainTab";
import LearningSphere from "./learning-page/LearningSphere";
function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={MainTab}/>
        <Route exact path="/learning-sphere/:name" component={LearningSphere}/>
      </Switch>
    </Router>
  );
}

export default App;
