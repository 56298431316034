import "./App.css"
import "./simplier-styling.css"
import "./player-card/Animations.css"
import PlayerCard from "./player-card/PlayerCard";
import Menu from "./menu/Menu";

function MainTab() {
    return (
      <div className="App md-stack h-mobile w-mobile">
        <PlayerCard/>
        <Menu/>
      </div>)
  }

export default MainTab