export const knowledges : Array<{
    title: string;
    link: string;
    description: string;
  }> =  [
    {
       title: "Design Patterns",
       link: "/learning-sphere/design-patterns",
       description: "Learn to use design patterns as a reusable form of a solution to a design problem"
    },
    {
      title: "Binary System",
      link: "/learning-sphere/binary-system",
      description: "Learn what is a binary and how to convert it between different numbering systems"
   }
]