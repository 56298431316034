import React, { useEffect, useState } from 'react'
import "./CodeModal.css"

interface CodeModal {
    title: string,
    isOpen: boolean,
    handleClose: Function,
    snippet: any
}

export default function CodeModal(props : CodeModal) {
    useEffect(() => {
        const modal = document.querySelector("body");
        props.isOpen ? modal?.classList.add('Body-remove__overlow') : modal?.classList.remove('Body-remove__overlow')
    }, [props.isOpen])
  return (
    <>
    {props.isOpen && <div className='CodeModal'>
        <div className="CodeModal-header">{props.title}</div>
        <div className="CodeModal-content">
            {props.snippet}
        </div>
        <div className="CodeModal-footer">
            <div className="CodeModal-close" onClick={() => props.handleClose()}>
                <div>
                    Close
                </div>
            </div>
        </div>
        
    </div>
    }
    {props.isOpen && <div className='CodeModal-backdrop'></div>}
    </>
  )
}
