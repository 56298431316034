import React, { useEffect, useState } from 'react'
import CodeModal from '../code-modal/CodeModal'
import "./Snippets.css"
import { snippets } from './SnippetsArray'
function Snippets() {
    const [isOpenMenu, setIsOpen] = useState<boolean>(false);
    const [titleNow, setTitleNow] = useState<string>("");
    const [snippetNow, setSnippetNow] = useState<string>("");
    const handleClose = () => {setIsOpen(false)}
    const handleOpen = (data : string, snippet : any) => {setIsOpen(true); setTitleNow(data); setSnippetNow(snippet)}
    
  return (
    <div className='Snippets'>
        {snippets.map(item => {
            const {title, description, snippet} = item
            return (
                <>
                <div className='Snippets_box' onClick={() => handleOpen(title, snippet)}>
                    <div className='Snippets_title'>{title}</div>
                    <div className='Snippets_description'>{description}</div>
                </div>
                </>
            );
        })}
        <CodeModal title={titleNow} snippet={snippetNow} isOpen={isOpenMenu} handleClose={handleClose}/>
    </div>
  )
}

export default Snippets