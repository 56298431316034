import React from 'react'
import { iconsForMenu } from '../../icons/icons'
import "./About.css"

function About() {
  return (
    <div className='About'>
      <div className='paragraph'>
        Clicking this tab insinuated your wonder of learning about me. Sorry to burst your bubble though, nothing peculiar. Mortal being, young soul with 
        the desire to be smarter than now (the future has not arrived, but when it occurs it will be the new now). The objective is to have knowledge about
        everything, in other words to grasp the power of computing. That is a very abstract thing to say... I know, and quite impossible. Duh!
        <br/><br/>
        My free time, which I rarely have due to developing a software to improve 3D immersive visualisation for the web space(as well as for high-end machines)
        I make use of it for broadening my mind with the vast information about computer languages, technologies and the internet. 
        <br/><br/>
        - Why? To put it candidly, because I want to know everything or I get vexed for the fact that I do not know the entirety. Maybe I got some mental issue, who knows.
        Either way, it makes me ambitious, hard-working and I would not change a thing about it.
        <br/><br/> Unfortunately, getting vexed is not enough for me to get off my bed and sit in front of the computer in order to absorb new information.
        So, I figured that if I publish that content from my brain somewhere, I would have the proper mindset to try and have fun doing it. 
        So where is that somewhere? - Yep you guessed it (at least I hope otherwise I would judge you :-) ) 
        <br/><br/>
        The purpose of this webste is to give me the proper stimulus of educating myself with new bytes every single day. So, I am implying that this space
        is more for me than it is for you (the one reading this), so do not judge my personal web space, nor be rude in any way as it is not constructed in any way
        to your likings, but solely for mine.
        <br/><br/>
        I am not going to state my character, whether hard-working, team player, flexible or any other job qualification(even though I am) as this is not a CV.
        <br/><br/>
        If you would like to get in touch with me, whether it is for a chit-chat, help, or brain storming of any kind, I will leave my info below.
        <div className='social-icons-flex'>
          <a href="https://mk.linkedin.com/in/joanisketikidis"><button className='icon'>{iconsForMenu.linkedIn}<span className='px-3 py-2 social-title'>LinkedIn</span></button></a>
          <a href = "mailto: joanisketikidis@gmail.com"><button className='icon'>{iconsForMenu.email}<span className='px-3 py-2 social-title'>Email</span></button></a>
        </div>
        {/* The vision of our product is really simple: sharing and remembering experiences 
        independent of space and time so we can expand the consciousness of humanity. */}
      </div>
    </div>
  )
}

export default About