import React, { ReactComponentElement, ReactElement, useEffect, useRef } from 'react'
import './LineTitle.css'

interface LineComponentProps {
  title: string,
  children: ReactElement[]
}

function LineTitle(props : LineComponentProps) {
  const lineLeft = useRef<HTMLDivElement>(null)
  const lineRight = useRef<HTMLDivElement>(null)
  const titleCenter = useRef<HTMLDivElement>(null)

  useEffect(() => {
    lineLeft.current!.style.width = '0'
    lineRight.current!.style.width = '0'
    titleCenter.current!.style.opacity = '0'
      setTimeout(() => {
          lineLeft.current!.style.transition = 'all 1s ease-in'
          lineRight.current!.style.transition = 'all 1s ease-in'
          titleCenter.current!.style.transition = 'all 1s ease-in'
          titleCenter.current!.style.opacity = '1'
          lineLeft.current!.style.width = "35%";
          lineRight.current!.style.width = "35%"
      }, 2000)
  })
  return (
      <div className='LineTitle'>
        <div className='LineTitle-container'>
          <div ref={lineLeft} className='LineTitle-line'></div>
          <div ref={titleCenter} className='LineTitle-title text-sm'>{props.title}</div>
          <div ref={lineRight} className='LineTitle-line'></div>
        </div>
          {props.children}
      </div>
  )
}

export default LineTitle