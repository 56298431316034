import React from 'react'
import LineTitle from '../components/line-title/LineTitle'
import PercentageBar from '../components/percentage-bar/PercentageBar'
import List from '../components/bullet-list/List'
import "./PlayerCard.css"
import ConnectedLine from '../components/connected-line/ConnectedLine'
function PlayerCard() {
  return (
    <div className='PlayerCard align-bottom align-bottom-mobile'>
        <div className='PlayerCard-container mxw-5 mnw-3'>
            <div className='PlayerCard-name'>Joanis Ketikidis</div>
            <div className='PlayerCard-underline fill-out-width-animation'></div>
            <div className='text-sm fade-in-animation opacity-0'>Owner and CEO @ <a target='_blank' href="https://www.exelsphere.com">Exelsphere</a></div>
            <div className='text-sm fade-in-animation opacity-0'>Software Engineer @ <a target='_blank' href="https://www.one-inside.com">One-Inside</a></div>
            <div className='text-sm fade-in-animation opacity-0'>VR Developer & Lead Integration Engineer @ <a target='_blank' href="https://inside-reality.com">Inside-Reality</a></div>
            <LineTitle title='Languages'>
                <PercentageBar title='JavaScript' percentage={100}/>
                <PercentageBar title='Unreal C++' percentage={86}/>
                <PercentageBar title='Action Script' percentage={100}/>
                <PercentageBar title='TypeScript' percentage={100}/>
                <PercentageBar title='GLSL' percentage={68}/>
                <PercentageBar title='SQL' percentage={100}/>
            </LineTitle>
            <div className='spacer-md'/>
            <LineTitle title='Achievements'>
                <div className='spacer-md'/>
                <List text='Win 1st place at the CodeFest 2018 competition.'/>
                <List text='Make a Facebook game with 100.000 monthly players.'/>
                <List text='Get funded by FITR for “Prolonging the Life Span of Solar Panels” Project.'/>
                <List text='Acquire x7 regional 1st place diplomas.'/>
            </LineTitle>
            <div className='spacer-md'/>    
            <LineTitle title='Skill Set'>
                <ConnectedLine title='React' year='2019-2023'/>
                <ConnectedLine title='Angular' year='2020-2021'/>
                <ConnectedLine title='Electron' year='2020-2023'/>
                <ConnectedLine title='ThreeJS' year='2020-2023'/>
                <ConnectedLine title='Unreal Engine' year='2018-2023'/>
                <ConnectedLine title='NodeJS' year='2018-2023'/>
            </LineTitle>
        </div>
    </div>
  )
}

export default PlayerCard