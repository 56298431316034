import React from 'react'
import {Link} from 'react-router-dom'
import "./Knowledge.css"
import { knowledges } from './KnowledgeArray'

function Knowledge() {
  return (
    <div className='Knowledge'>
    {knowledges.map(item => {
        const {title, link, description} = item
        return (
            <>
            <Link to={link}>
              <div className='Knowledge_box'>
                <div className="Knowledge_title">{title}</div>
                <div className='Knowledge_description'>{description}</div>
              </div>
            </Link>
            </>
        );
    })}
</div>
  )
}

export default Knowledge