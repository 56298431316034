const blue = (element: string) => {
  return <span style={{ color: 'rgb(79, 193, 255)' }}>{element}</span>
}
const yellow = (element: string) => {
  return <span style={{ color: 'rgb(220, 220, 170)' }}>{element}</span>
}
const pink = (element: string) => {
  return <span style={{ color: 'rgb(196, 133, 191)' }}>{element}</span>
}
const green = (element: string) => {
  return <span style={{ color: 'rgb(77, 201, 176)' }}>{element}</span>
}
const gray = (element: string) => {
  return <span style={{ color: 'gray' }}>{element}</span>
}

const lowGreen = (element: string) => {
  return <span style={{ color: '#b5cda8' }}>{element}</span>
}

const red = (element: string) => {
  return <span style={{ color: '#F88379' }}>{element}</span>
}

const normal = (element: string) => {
  return <span style={{ color: 'unset' }}>{element}</span>
}

const string = (element: string) => {
  return <span style={{ color: '#cd9278' }}>{element}</span>
}

const babyblue = (element: string) => {
  return <span style={{ color: '#99d8f9' }}>{element}</span>
}

export const learningBooks: Array<{
  links: Array<{ title: string, to: string }>,
  title: string;
  route: string;
  published: string;
  book: any
}> = [
    {
      links: [
        {
          title: "Creational",
          to: "Creational Design Patterns"
        },
        {
          title: "Structural",
          to: "Creational Design Patterns"
        },
        {
          title: "Behavioral",
          to: "Creational Design Patterns"
        }
      ],
      title: "Design Patterns",
      route: "design-patterns",
      published: "Published: 06/01/2023",
      book: <>
        <h1 className='strike-through-text text-m'>Introduction</h1>
        <p>Design patterns are here to solve some occurring problems in software design. The creator behind them is
          <a href="https://en.wikipedia.org/wiki/Christopher_Alexander" target="_blank"> Christopher Alexander </a>
          , an architect and design theorist. A collection of organized and coherent patterns form a pattern language, where each one of the pattern describes a problem and the solution to that
          same problem.
        </p>
        <p>
          There are {lowGreen("23")} original design patterns which split into three categories: <strong>{red("Creational")}</strong>, <strong>{red("Structural")}</strong> and <strong>{red("Behavioral")}</strong> patterns, but more have originated.
        </p>
        <h1 className='strike-through-text text-m' id="creational-id">Creational Design Patterns</h1>

        <details>
          <summary style={{ fontSize: "20px" }}>Builder</summary>
          <p>
            The Builder Pattern is used to create complex objects by separating the object construction from its representation. It allows the creation of different
            object configurations from within the same class and also hides the creation details from the client code.<br /><br />

            {red("Analogy:")} A chef creates a dish by selecting the ingredients and mixing them in specific order. After the chef is done cooking the dish, it then gets delivered to the
            customer in its final form without the customer knowing what process the dish underwent.<br /><br />

            {red("Participants:")} It involves 4 participants.
            <ul>
              <li>Builder interface.</li>
              <li>ConcreteBuilder classes implementing the Builder interface.</li>
              <li>Director class which directs the construction process.</li>
              <li>Product class that represents the complex object being built.</li>
            </ul><br />

            {red("Process:")}
            <ul>
              <li>The client creates a Director object and passes a ConcreteBuilder object to it, in our case SweetBaker or SaltyBaker gets passed as reference to the Bakery class via the hire method.</li>
              <li>The Director class calls methods to build the compex object, in our case the Bakery class once the hire method is called, the start method is called and returns an object.</li>
              <li>The returned object from the Director class, in our case the Bakery, it allows us to call methods one by one like a chain.</li>
              <li>After all needed steps are called, the final state of the object should be returned for the representation to the client.</li>
            </ul><br />

            {red("Example:")}<br /><br />

            {blue("class")} {green("Bakery")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("hire")}{pink("(")}{babyblue("builder")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("builder")}.{yellow("start")}{blue("()")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("return")} {babyblue("builder")}.{yellow("get")}{blue("()")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")};<br /><br />

            {blue("class")} {green("SweetBaker")} {yellow("{ }")};<br /><br />

            {blue("class")} {green("SaltyBaker")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("bread")} = {blue("null")};<br /><br />
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("start")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("bread")} = {blue("new")} {green("SaltyBread")}{blue("()")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("return")} {blue("this")}.{babyblue("bread")};<br /><br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("get")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("return")} {blue("this")}.{babyblue("bread")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")};<br /><br />

            {blue("class")} {green("SweetBread")} {yellow("{ }")};<br /><br />

            {blue("class")} {green("SaltyBread")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("calories")} = {lowGreen("0")};<br /><br />
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("addIngredients")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("calories")} += {lowGreen("1500")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("return")} {blue("this")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("addChese")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("calories")} += {lowGreen("200")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("return")} {blue("this")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("addHam")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("calories")} += {lowGreen("800")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("return")} {blue("this")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("getCalories")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("return")} {blue("this")}.{babyblue("calories")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />
            {yellow("}")};<br /><br />

            {blue("let")} {babyblue("myBakery")}  = {blue("new")} {green("Bakery")}{yellow("()")};<br />
            {blue("let")} {babyblue("mySaltyBaker")}  = {blue("new")} {green("SaltyBaker")}{yellow("()")};<br />
            {blue("let")} {babyblue("saltyBread")}  = {babyblue("myBakery")}.{yellow("hire(")}{babyblue("mySaltyBaker")}{yellow(")")};<br /><br />

            {babyblue("console")}.{yellow("log(")}{babyblue("saltyBread")}.{yellow("addHam")}{pink("()")}.{yellow("addChese")}{pink("()")}.{yellow("addIngredients")}{pink("()")}.
            {yellow("getCalories")}{pink("()")}{yellow(")")}; {gray("//Outputs 2500 ")}<br /><br />

            {red("Benefits:")} useful tool in object-oriented programming for constructing complex objects in a flexible and extensible way, while isolating the object creation from the client code. It
            also gets rid of the {red("Telescoping Constructor")} which represents a constructor accepting a lot of paramaters that might not be used but are needed to be supplied so the object is instantiated.<br /><br />
            {red("Drawbacks:")} significant increase of code complexity since the pattern requires creating multiple new classes.
          </p>

        </details>

        <details>
          <summary style={{ fontSize: "20px" }}>Factory</summary>
          <p>
            The Factory Pattern is used to create objects without exposing the instantiation logic to the client. It provides a way for the client to use a common interface
            to create different types of objects.<br /><br />

            {red("Analogy:")} A person is hungry so he goes to a pizza restaurant. He looks at the menu and specifies which pizza he wants. The chef, which acts as a ConcreteCreator then creates
            the specific pizza using the factory method. The client is unaware of how to pizza is made or its process, he just specifies what he wants to get and the chef (ConcreteCreator) delivers.<br /><br />

            {red("Participants:")} It involves 2 participants.
            <ul>
              <li>The creator class that defines the interface for object creation.</li>
              <li>The ConcreteProduct classes that implement the interface and create specific types of objects.</li>
            </ul><br />

            {red("Process:")}
            <ul>
              <li>The client creates a ConcreteCreator which holds the creation of specific objects, in our case it is the PizzaCreator class.</li>
              <li>The client calls one of the methods depending on which method he wants to get.</li>
              <li>The ConcreteCreator returns the ConcreteProduct, in our case it is either the Pepperoni or the Margharita class.</li>
            </ul><br />

            {red("Example:")}<br /><br />
            {blue("class")} {green("PizzaCreator")} {yellow("{")} <br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("pizza")} = {blue("null")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("orderPizza")}{pink("(")}{babyblue("type")}{pink(")")} {pink("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("if")} {blue("(")}{babyblue("type")} === {string("\"pepperoni\"")} {blue(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("pizza")} = {blue("new")} {green("Pepperoni")}{pink("()")}.{babyblue("pizza")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("} ")}{pink("else")} {blue("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("pizza")} = {blue("new")} {green("Margharita")}{pink("()")}.{babyblue("pizza")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("}")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")};<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("info")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("return")} {string("`You just ordered the")}
            &nbsp;{blue("$")}{blue("{")}{blue("this")}.{babyblue("pizza")}{blue("}")}{string(" pizza`")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")};<br />
            &nbsp;{yellow("}")}<br /><br />

            {blue("class")} {green("Pepperoni")} {blue("{")} <br />
            &nbsp;&nbsp;&nbsp;&nbsp;{blue("constructor")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("pizza")} = {string("\"pepperoni\"")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {blue("}")};<br /><br />

            {blue("class")} {green("Margharita")} {blue("{")} <br />
            &nbsp;&nbsp;&nbsp;&nbsp;{blue("constructor")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("pizza")} = {string("\"margharita\"")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {blue("}")};<br /><br />


            {blue("let")} {babyblue("factory")} = {blue("new")} {green("PizzaCreator")}{yellow("()")};<br />
            {blue("const")} {babyblue("myOrder")} = {babyblue("factory")}.{yellow("orderPizza(")}{string("`pepperoni`")}{yellow(")")};<br />
            {babyblue("console")}.{yellow("log")}{yellow("(")}{babyblue("factory")}.{yellow("info")}{pink("()")}{yellow(")")}; {gray("//You just ordered the pepperoni pizza")}<br /><br />

            {red("Benefits:")} useful way of creating objects in a flexible and extensible way where details are hidden from the client, easy addition of new product types.<br /><br />
            {red("Drawback:")} over time may become more complicated due to the introduction of multiple classes needed to implement the pattern.
          </p>

        </details>

        <details>
          <summary style={{ fontSize: "20px" }}>Abstract Factory</summary>
          <p>
            The Abstract Factory Pattern provides an interface for creating families related by a common theme. This pattern is useful when a system needs to work with multiple families of objects, but the client
            code should not depend on the specific implementation of those objects.<br /><br />

            {red("Analogy:")} A restaurant offers different types of cuisine for its customers. An italian cuisine could have pizza and pasta dishes, the chinese cuisine
            could have noodles and rice dishes. The cuisines represent a ConcreteFactory. Each cuisine creates related objects (dishes) which in this case is the ConcreteProduct.<br /><br />

            {red("Participants:")} It involves 2 participants.
            <ul>
              <li>The Abstract Factory that defines interface for creating families of objects</li>
              <li>The Concrete Factories that implement the interface to create specific families of obvjects</li>
            </ul><br />

            {red("Process:")}
            <ul>
              <li>The client creates a Concrete Factory object using the Abstract Factory Interface</li>
              <li>The Concrete Factory creates family of related objects</li>
              <li>The client uses the objects created by the Concrete Factory</li>
            </ul><br />

            {red("Example:")}<br /><br />


            {gray("//abstract class RestaurantFactory {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{gray("//createDish(type);")}<br />
            {gray("//}")}<br /><br />


            {blue("class")} {green("ItalianRestaurantFactory")} {yellow("{")} {gray("//extends RestaurantFactory")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("createDish")}{pink("(")}{babyblue("type")}{pink(")")} {pink("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("switch")} {blue("(")}{babyblue("type")}{blue(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("case")} {string("\"spaghetti\"")}: {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("return")} {blue("new")} {green("Spaghetti")}{pink("()")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("break")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{yellow("}")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("}")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")};<br /><br />

            {blue("class")} {green("MexicanRestaurantFactory")} {yellow("{")} {gray("//extends RestaurantFactory")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("createDish")}{pink("(")}{babyblue("type")}{pink(")")} {pink("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("switch")} {blue("(")}{babyblue("type")}{blue(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("case")} {string("\"burrito\"")}: {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("return")} {blue("new")} {green("Burrito")}{pink("()")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("break")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{yellow("}")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("}")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")};<br /><br />

            {gray("//abstract class Dish {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{gray("//prepare();")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{gray("//cook();")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{gray("//serve();")}<br />
            {gray("//}")}<br /><br />

            {blue("class")} {green("Spaghetti")} {yellow("{")} <br />
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("prepare")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("console")}.{yellow("log")}{blue("(")}{string("\"Preparing spaghetti\"")}{blue(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("cook")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("console")}.{yellow("log")}{blue("(")}{string("\"Cooking spaghetti\"")}{blue(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("serve")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("console")}.{yellow("log")}{blue("(")}{string("\"Serving spaghetti\"")}{blue(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />
            {yellow("}")}<br /><br />

            {blue("class")} {green("Burrito")} {yellow("{")} <br />
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("prepare")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("console")}.{yellow("log")}{blue("(")}{string("\"Preparing burrito\"")}{blue(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("cook")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("console")}.{yellow("log")}{blue("(")}{string("\"Cooking burrito\"")}{blue(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("serve")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("console")}.{yellow("log")}{blue("(")}{string("\"Serving burrito\"")}{blue(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />
            {yellow("}")}<br /><br />

            {blue("let")} {babyblue("italianFactory")} = {blue("new")} {green("ItalianRestaurantFactory")}{yellow("()")};<br />
            {blue("let")} {babyblue("mexicanFactory")} = {blue("new")} {green("MexicanRestaurantFactory")}{yellow("()")};<br /><br />

            {babyblue("italianFactory")}.{yellow("createDish(")}{string("\"spaghetti\"")}{yellow(")")}.{yellow("prepare()")}; {gray("//preparing spaghetti")}<br /><br />

            {red("Benefits:")} useful way of creating families of related objects in an extensible and flexible way, allows for easy addition of new families of objects, and code is not tightly coupled. <br /><br />
            {red("Drawback:")} over time the complexity of the codebase could increase, as each new family requires new Concrete Factory.
          </p>
        </details>

        <details>
          <summary style={{ fontSize: "20px" }}>Prototype</summary>
          <p>
            The Prototype Pattern allows the creation of new objects based on a pre-existing instance, known as the prototype.<br /><br />

            One objects properties may be copied, cloned to another object using two methods. Shallow Cloning and Deep Cloning.<br />
            Shallow Cloning creates a new object with the same values as the original object, while Deep Cloning creates a new object with copies of all the original objects values and references.<br /><br />

            {red("Analogy:")} A customer goes to a restaurant and orders a dish from the menu. That dish, the prototype in this case is always prepared, cooked and served the same way as the ingredients do
            not change unless upon the customers request of some minor changes. The chef starts cooking the dish by using the same ingredients and process he always does, in this case he clones it and then
            customizes it upon the customers preference.<br /><br />

            {red("Participants:")} It involves 2 participants
            <ul>
              <li>The Prototype which is an abstract class or interface defining the methods for cloning itself</li>
              <li>The Concrete Prototype which implements the interface and defines the logic of the cloning method.</li>
            </ul><br />

            {red("Process:")}
            <ul>
              <li>The Prototype interface declares the methods for cloning, usually just one method called "clone".</li>
              <li>The ConcretePrototype classes implement the Prototype interface and write the logic and details inside the clone method.</li>
              <li>The client creates an instance of the ConcretePrototype and calls the clone method to create new objects.</li>
              <li>The client can modify or keep the properties same as the cloned object.</li>
            </ul>

            {red("Example:")}<br /><br />

            {gray("//interface Dish {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{gray("//clone()")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{gray("//setName()")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{gray("//setPrice()")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{gray("//printDetails()")}<br />
            {gray("//}")}<br /><br />

            {blue("class")} {green("PastaDish")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("#name")} = {string("' '")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("#price")} = {lowGreen("0")};<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{blue("constructor")}{pink("(")}{babyblue("name")}, {babyblue("price")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("name")} = {babyblue("name")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("price")} = {babyblue("price")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("clone")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("return")} {blue("new")} {green("PastaDish")}{blue("(this")}.{babyblue("name")}, {blue("this")}.{babyblue("price")}{blue(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("setName")}{pink("(")}{babyblue("name")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("name")} = {babyblue("name")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("setPrice")}{pink("(")}{babyblue("price")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("price")} = {babyblue("price")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("printDetails")}{pink("(")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("console")}.{yellow("log")}{blue("(")}{string("\"The dish is a \"")} + {blue("this")}.{babyblue("name")} + {string("\" dish costing \"")} + {blue("this")}.{babyblue("price")}{blue(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />

            {blue("let")} {babyblue("pastaPrototype")} = {blue("new")} {green("PastaDish")}{yellow("(")}{string("\"Spaghetti Carbonara\"")}, {lowGreen("12.99")}{yellow(")")};<br /><br />

            {blue("let")} {babyblue("macNCheese")} = {babyblue("pastaPrototype")}.{yellow("clone()")};<br />
            {babyblue("macNCheese")}.{yellow("setName(")}{string("\"Mac 'N Cheese\"")}{yellow(")")};<br />
            {babyblue("macNCheese")}.{yellow("printDetails()")}; {gray("//The dish is a Mac 'N Cheese dish costing 12.99")}<br /><br />

            {red("Benefits:")} simplifies object creation of similar or identical objects by reducing the amount of time and resources required to create new objects<br /><br />

            {red("Drawbacks:")} memory issue if cloned objects are not disposed of or garbage collected as well as limited use cases for the use of this prototype.
          </p>


        </details>

        <details>
          <summary style={{ fontSize: "20px" }}>Singleton</summary>
          <p>
            The Singleton Pattern restricts the instantation of a class to a single object. It ensures that a class has only one instance and provides global point of access to that instance.<br /><br />

            In JavaScript there are also static classes which cannot be instantiated and you can only access its static members directly through the class name.
            Main difference is, Singleton is a design pattern and a class whch ensures only one instance of a class is created, while static class is a class which cannot be
            instantiated and all its members are static.<br /><br />

            You would use a singleton when you need to maintain a single instance of a class throughout the lifetime of an application, while you would use a static class when you want to
            group related methods together and share them across multiple instances of other classes, but don't need to maintain any state.<br /><br />

            {red("Analogy:")} In a restaurant there are chefs and there is one head chef. He oversees the kitchen and makes key decisions about menu, ingredients and cooking methods.
            Just like that, the Singleton patterns ensures there is one object who may manage resources or decisions.<br /><br />

            {red("Participants:")} It involves 1 participant.
            <ul>
              <li>The class itself</li>
            </ul><br />

            {red("Process:")}
            <ul>
              <li>Create a class that has a constructor. When called if no instance exist, it creates on. On each next call it won't create a new instance, it would rather check the existance
                of an instance and then return that instance.
              </li>
            </ul><br />

            {red("Example:")}<br /><br />

            {blue("class")} {green("SingletonGameInstance")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("username")} = {string("\" \"")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("level")} = {lowGreen("0")};<br /><br />
            &nbsp;&nbsp;&nbsp;&nbsp;{blue("constructor")} {pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("if")} {blue("(")}{green("SingletonGameInstance")}.{babyblue("instance")}{blue(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("throw")} {blue("new")} {green("Error")}{yellow("(")}{string("\"Cannot instantiate the same class multiple times\"")}{yellow(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{gray("//Or return MyClass.instance to return always the same one class")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("}")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{green("SingletonGameInstance")}.{babyblue("instance")} = {blue("this")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />
            {blue("let")} {babyblue("instance1")} = {blue("new")} {green("SingletonGameInstance")}{yellow("()")};<br />
            {blue("let")} {babyblue("instance2")} = {blue("new")} {green("SingletonGameInstance")}{yellow("()")}; {gray("//Cannot instantiate the same class multiple times")}<br /><br />

            {red("Benefits:")} easy access to a single instance when a resource is needed to be shared across multiple parts in your program.<br /><br />

            {red("Drawbacks:")} potential concurrency issues may occur if multiple threads are accessing the same instance.
          </p>

        </details>

        <h1 className='strike-through-text text-m' id="structural-id">Structural Design Patterns</h1>
        <details>
          <summary style={{ fontSize: "20px" }}>Adapter</summary>
          <p>
            The Adapter Pattern, also known as wrapper allows two incompatible interfaces to work together.<br /><br />

            {red("Analogy:")} You are from Europe and decided to travel to the land of the freedom, America. The outlets are different there in shape than where you come from which means
            that your device's plug won't fit in the outlet. So your adapter does not match the electrical outlet, in other words the interface of the adapter is incompatible with the interface
            of the electrical outlet. In order to make it work, you would use some conversion add-on to the adapter to make the interfaces compatible.<br /><br />

            {red("Participants:")} It involves 3 participant.
            <ul>
              <li>The Target interface class</li>
              <li>The Adapter class</li>
              <li>The Adaptee class</li>
            </ul><br />

            {red("Process:")}
            <ul>
              <li>Create the target interface which describes what other classes have to follow in order to be able to collaborate</li>
              <li>The Adaptee class which needs to adapt to work with other class's interface</li>
              <li>The Adapter class which implements the Target interface and holds the adaptee reference. It adapts the Adaptee interface to match the Target interface</li>
            </ul><br />

            {red("Example:")}<br /><br />

            {blue("class")} {green("EuroWallet")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("getJsonData")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{gray("//{balance: 2.80}")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />

            {blue("class")} {green("DollarWallet")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("getXmlData")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{gray("//<balance>5</balance>")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />

            {blue("function")} {yellow("someXmlToJsonCovertor(")}{babyblue("xml")}{yellow(") {")}{gray("/* some conversion login */")}{yellow("}")}<br /><br />

            {blue("class")} {green("DollarAdapter")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("getJsonData")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("return")} {yellow("someXmlToJsonConvertor")}{blue("(this")}.{babyblue("dollar")}.{yellow("getXmlData")}{blue(")")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />

            {blue("let")} {babyblue("euroWalletJsonData")} = {blue("new")} {green("EuroWallet")}{yellow("()")};<br />
            {blue("let")} {babyblue("dollarAdapter")} = {blue("new")} {green("DollarAdapter")}{yellow("()")};<br /><br />

            {babyblue("euroWallet")}.{yellow("getJsonData()")} {gray("// {balance: 2.80}")}<br />
            {babyblue("dollarAdapter")}.{yellow("getJsonData()")} {gray("// {balance: 5}")}<br /><br />

            {red("Benefits:")} reuse existing code that may not be compatible with the rest of the system. It reduces development time.<br /><br />

            {red("Drawbacks:")} reduce in performance by adding additional layer of indication.
          </p>

        </details>

        <details>
          <summary style={{ fontSize: "20px" }}>Bridge</summary>
          <p>
            The Bridge Pattern allows two components to vary independently, which enhances flexibility, extensibility and maintability.<br /><br />

            {red("Analogy:")} Imagine a construction project where a bridge needs to be built over a river. The bridge has two components, roadway and support structure. The
            Abstraction defines the high-level functionality of the bridge such as width, height, length, weight capacity. The implementation provides low level functionality, such as materials used
            to build the support structure.<br /><br />

            {red("Participants:")} It involves 4 participant.
            <ul>
              <li>The Abstraction</li>
              <li>The Implementation</li>
              <li>The Refined Abstraction class</li>
              <li>The Concrete Implementation class</li>
            </ul><br />

            {red("Process:")}
            <ul>
              <li>Create the abstraction which provides high-level control logic.</li>
              <li>Create the implementation which declares interface that's common for all concrete implementations.</li>
              <li>Create the concrete implementations implementing the interface</li>
              <li>Create the refined abstraction which provide variants of control logic.</li>
            </ul><br />

            {red("Example:")}<br /><br />

            {gray("//Implementation Layer, handles low level calls, it is something like API")}<br />
            {blue("class")} {green("Device")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("setVolume")}{pink("(")}{babyblue("percent")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("console")}.{yellow("log")}{blue("(")}{string("\"Audio is at \"")} + {babyblue("percent")} + {string("\"%\"")}{blue(")")}{pink("}")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("}")}<br /><br />
            {gray("//Concrete implementation that follows the implementation layer")}<br />
            {blue("class")} {green("Tv")} {blue("extends")} {green("Device")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("volume")} = {green("0")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{blue("constructor")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("super()")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("setVolume")}{pink("(")}{babyblue("percent")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("super")}.{yellow("setVolume")}{blue("(")}{babyblue("percent")}{blue(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />
            {gray("//Concrete implementation that follows the implementation layer")}<br />
            {blue("class")} {green("Radio")} {blue("extends")} {green("Device")} {yellow("{ }")}<br /><br />

            {gray("//Abstraction Layer, handles high-level calls, it serves something like a graphical user interface GUI")}<br />
            {blue("class")} {green("CheapRemoteControl")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("#device")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{blue("constructor")}{pink("(")}{babyblue("device")}{pink("){")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("#device")} = {babyblue("device")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("volumeUp")}{pink("(")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("#device")}.{yellow("setVolume")}{blue("(this")}.{babyblue("#device")}.{babyblue("volume")} + {green("10")}{blue(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />

            {gray("//Refined abstraction class, which is optional, contains variants of control logic")}<br />
            {blue("class")} {green("ExpensiveRemoteControl")} {blue("extends")} {green("CheapRemoteControl")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("#device")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{blue("constructor")}{pink("(")}{babyblue("device")}{pink("){")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("super(")}{babyblue("device")}{blue(")")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("#device")} = {babyblue("device")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("mute")}{pink("(")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("#device")}.{yellow("setVolume")}{blue("(")}{green("0")}{blue(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />

            {blue("let")} {babyblue("tv1")} = {blue("new")} {green("Tv")}{yellow("()")};<br />
            {blue("let")} {babyblue("cheapRemote")} = {blue("new")} {green("CheapRemoteControl")}{yellow("(")}{babyblue("tv1")}{yellow(")")};<br />
            {babyblue("cheapRemote")}.{yellow("volumeUp()")};<br /><br />

            {blue("let")} {babyblue("tv2")} = {blue("new")} {green("Tv")}{yellow("()")};<br />
            {blue("let")} {babyblue("expensiveRemote")} = {blue("new")} {green("ExpensiveRemoteControl")}{yellow("(")}{babyblue("tv2")}{yellow(")")};<br />
            {babyblue("expensiveRemote")}.{yellow("volumeUp()")};<br />
            {babyblue("expensiveRemote")}.{yellow("mute()")};<br /><br />
            {red("Benefits:")} separation of concerns as the abstraction and implementation are separatated, it allows each to vary independently and improves flexibility and modifiability<br /><br />
            {red("Drawbacks:")} can be overkill for simpler projects and requires more development time for the use of this pattern and an upfront design is needed.
          </p>
        </details>

        <details>
          <summary style={{ fontSize: "20px" }}>Composite</summary>
          <p>
            The Composite Pattern lets you compose objects into tree structures and then work with these structures as if they were individual objects.<br /><br />

            {red("Analogy:")} Just as a tree has branches that can have more branches or leaves attached to them, this pattern has objects that can contain composite objects or leaf objects. <br /><br />

            {red("Participants:")} It involves 3 participant.
            <ul>
              <li>The Component interface</li>
              <li>The Leaf</li>
              <li>The Container or known as Composite</li>
            </ul><br />

            {red("Process:")}
            <ul>
              <li>Create the component interface for actions common to both leaf and composite objects</li>
              <li>Create the Leaf which represents a single basic element of a tree that does not have subelements</li>
              <li>Create the container which has sub elements and delegates work to sub-elements, single leaf nodes.</li>
            </ul><br />

            {red("Example:")}<br /><br />


            {blue("class")} {green("MilitaryPerson")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("shoot")}{pink("(")}{babyblue("name")}{pink(") { }")}<br />
            {yellow("}")}<br /><br />

            {blue("class")} {green("Soldier")} {blue("extends")} {green("MilitaryPerson")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("name")} = {string("\" \"")};<br /><br />
            &nbsp;&nbsp;&nbsp;&nbsp;{blue("constructor")}{pink("(")}{babyblue("name")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("super()")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("name")} = {babyblue("name")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("shoot")}{pink("(")}{babyblue("name")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("return")} {string("\" has fired the gun \"")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("super")}.{yellow("shoot")}{blue("(")}{babyblue("name")}{blue(")")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />

            {blue("class")} {green("MilitaryOrganization")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("#soldiers")} = {pink("[ ]")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("soldier")} = {blue("null")};<br /><br />
            &nbsp;&nbsp;&nbsp;&nbsp;{blue("constructor")}{pink("(")}{babyblue("soldier")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("soldier")} = {babyblue("soldier")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("add")}{pink("(")}{babyblue("child")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("#soldiers")}.{yellow("push")}{blue("(")}{babyblue("child")}{blue(")")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("remove")}{pink("(")}{babyblue("child")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("let")} {babyblue("length")} = {blue("this")}.{babyblue("#soldiers")}.{blue("length")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("for")} {blue("(let")} {babyblue("i")} = {green("0")}; {normal("i < length")}; {babyblue("i++")}{blue(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("if")} {yellow("(")}{blue("this")}.{babyblue("#soldiers")}{pink("[")}{babyblue("i")}{pink("]")} === {babyblue("child")}{yellow(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("#soldiers")}.{yellow("splice")}{pink("(")}{babyblue("i")}, {green("1")}{pink(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("return")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{yellow("}")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("}")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("getChild")}{pink("(")}i{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("return")} {blue("this")}.{babyblue("#soldiers")}{blue("[")}{babyblue("i")}{blue("]")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("getChildren")}{pink("(")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("return")} {blue("this")}.{babyblue("#soldiers")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("hasChildren")}{pink("(")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("return")} {blue("this")}.{babyblue("#soldiers")}.{blue("length")} {normal(">")} {green("0")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("traverse")}{pink("(")}{babyblue("indent")}, {babyblue("node")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("console")}.{yellow("log")}{pink("(")}{green("Array")}{blue("(")}{babyblue("indent++")}{blue(")")}.{yellow("join")}{blue("(")}"-"{blue(")")} + {babyblue("node")}.{babyblue("soldier")}.{babyblue("name")} + {string("\" \"")} + {babyblue("node")}.{babyblue("soldier")}.{yellow("shoot")}{blue("()")}{pink(")")};<br /><br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("for")}{blue(" (let")} i = {green("0")}; {normal("i < node")}.{yellow("getChildren")}{blue("()")}.{blue("length")}; {babyblue("i++")}{blue(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{yellow("traverse")}{blue("(")}{babyblue("indent")}, {babyblue("node")}.{yellow("getChild(")}i{yellow(")")}{blue(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("}")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />
            {yellow("}")}<br /><br />

            {blue("let")} {babyblue("General1")} = {blue("new")} {green("MilitaryOrganization")}{yellow("(")}{blue("new")} {green("Soldier")}{pink("(")}"General"{pink(")")}{yellow(")")};<br />
            {blue("let")} {babyblue("Liutenant1")} = {blue("new")} {green("MilitaryOrganization")}{yellow("(")}{blue("new")} {green("Soldier")}{pink("(")}"Liutenant1"{pink(")")}{yellow(")")};<br />
            {blue("let")} {babyblue("Liutenant2")} = {blue("new")} {green("MilitaryOrganization")}{yellow("(")}{blue("new")} {green("Soldier")}{pink("(")}"Liutenant2"{pink(")")}{yellow(")")};<br />
            {blue("let")} {babyblue("Soldier1")} = {blue("new")} {green("MilitaryOrganization")}{yellow("(")}{blue("new")} {green("Soldier")}{pink("(")}"Soldier1"{pink(")")}{yellow(")")};<br />
            {blue("let")} {babyblue("Soldier2")} = {blue("new")} {green("MilitaryOrganization")}{yellow("(")}{blue("new")} {green("Soldier")}{pink("(")}"Soldier2"{pink(")")}{yellow(")")};<br />
            {blue("let")} {babyblue("Soldier3")} = {blue("new")} {green("MilitaryOrganization")}{yellow("(")}{blue("new")} {green("Soldier")}{pink("(")}"Soldier3"{pink(")")}{yellow(")")};<br />
            {blue("let")} {babyblue("Soldier4")} = {blue("new")} {green("MilitaryOrganization")}{yellow("(")}{blue("new")} {green("Soldier")}{pink("(")}"Soldier4"{pink(")")}{yellow(")")};<br />

            {babyblue("General1")}.{yellow("add(")}{babyblue("Liutenant1")}{yellow(")")};<br />
            {babyblue("General1")}.{yellow("add(")}{babyblue("Liutenant2")}{yellow(")")};<br /><br />

            {babyblue("Liutenant1")}.{yellow("add(")}{babyblue("Soldier1")}{yellow(")")};<br />
            {babyblue("Liutenant1")}.{yellow("add(")}{babyblue("Soldier2")}{yellow(")")};<br /><br />

            {babyblue("Liutenant2")}.{yellow("add(")}{babyblue("Soldier3")}{yellow(")")};<br />
            {babyblue("Liutenant2")}.{yellow("add(")}{babyblue("Soldier4")}{yellow(")")};<br /><br />
            {babyblue("General1")}.{yellow("traverse(")}{green("1")}, {babyblue("General1")}{yellow(")")};<br />

            {red("Benefits:")} provides flexible way to represent complex hierarchical structures of objects and be able to manipualate each one individually.<br /><br />
            {red("Drawbacks:")} affects performance but may be mitigated by using techniques like caching and lazy loading.
          </p>
        </details>

        <details>
          <summary style={{ fontSize: "20px" }}>Decorator</summary>
          <p>
            The Decorator pattern lets you attach new behaviors to objects by placing these objects inside a special wrapper objects that contain the behavior.<br /><br />

            {red("Analogy:")} Person dressing for a night out will start with one cloth first and then advance on by adding more clothes and accessories. The decorator is the same,
            you can start with one object and then add more functionality and behavior.<br /><br />

            {red("Participants:")} It involves 4 participant.
            <ul>
              <li>The Component interface</li>
              <li>The Concrete Component</li>
              <li>The Base Decorator class</li>
              <li>The Concrete Decorators classes</li>
            </ul><br />

            {red("Process:")}
            <ul>
              <li>Create the Component interface which declares common interface for both wrappers and wrapped objects</li>
              <li>Create the Concrete Component which is a class being wrapped. It contains basic behavior which can be altered by decorator</li>
              <li>Create the Base Decorator which has a reference field for a wrappee object (Concrete Component)</li>
              <li>Create the Concrete Decorators which define extra behavior that can be added to components dynamically.</li>
            </ul><br />

            {red("Example:")}<br /><br />

            {blue("class")} {green("EmailSender")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("send")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("console")}.{yellow("log")}{blue("(")}{string("\"Email Sent\"")}{blue(")")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />

            {blue("class")} {green("NotifierService")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("wrappee")} = {blue("null")};<br /><br />
            &nbsp;&nbsp;&nbsp;&nbsp;{blue("constructor")}{pink("(")}{babyblue("wrappee")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("wrappee")} = {babyblue("wrappee")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("send")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("wrappee")}.{yellow("send")}{blue("()")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />

            {blue("class")} {green("SmsSender")} {blue("extends")} {green("NotifierService")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("send")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("console")}.{yellow("log")}{blue("(")}{string("\"Sms Sent\"")}{blue(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("super")}.{yellow("send")}{blue("()")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />

            {blue("class")} {green("InstagramSender")} {blue("extends")} {green("NotifierService")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("send")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("console")}.{yellow("log")}{blue("(")}{string("\"Instagram Sent\"")}{blue(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("super")}.{yellow("send")}{blue("()")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />

            {blue("let")} {babyblue("emailSender")} = {blue("new")} {green("EmailSender")}{yellow("()")};<br />
            {blue("let")} {babyblue("smsSender")} = {blue("new")} {green("EmailSender")}{yellow("(")}{babyblue("emailSender")}{yellow(")")};<br />
            {blue("let")} {babyblue("instagramSender")} = {blue("new")} {green("InstagramSender")}{yellow("(")}{babyblue("smsSender")}{yellow(")")};<br /><br />
            {babyblue("instagramSender")}.{yellow("send()")};<br /><br />

            {red("Benefits:")} allows adding of behaviour to an object either statically or dynamically without affecting behavior of objects from same class.<br /><br />
            {red("Drawbacks:")} hard to manage if multiple objects are decorated and increases performance of additional dynamic methods calls and object creation.
          </p>
        </details>

        <details>
          <summary style={{ fontSize: "20px" }}>Facade</summary>
          <p>
            The Facade pattern provides a simplified interface to a library, a framework or any other complex set of classes where different methods are combined to create one outcome.<br /><br />

            {red("Analogy:")} As the name implies, it could be a building's facade where the outer part is all nice and simple, while hiding the complex internal workings of the building.<br /><br />

            {red("Participants:")} It involves 3 participant.
            <ul>
              <li>The Facade Class</li>
              <li>The Subsystem Class</li>
              <li>(OPTIONAL) Additional Facade to prevent pollution</li>
            </ul><br />

            {red("Process:")}
            <ul>
              <li>Create a Facade class which will simplify the complex subsystem. It is responsible for managing and coordinating the interactions between the client and the subsystem.</li>
              <li>The subsystem which is a collection of classes that work together on a specific task needs to be researched in order to be simplified and put together in the Facade class</li>
            </ul><br />

            {red("Example:")}<br /><br />


            {blue("class")} {green("AmazonWarehouse")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{blue("constructor")}{pink("(")}{babyblue("product")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("console")}.{yellow("log")}{blue("(")}{babyblue("product")} + {string("\" will be added to warehouse and ready for shipping\"")}{blue(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />

            {blue("class")} {green("AmazonPackaging")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{blue("constructor")}{pink("(")}{babyblue("product")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("product")} = {babyblue("product")} +  {string("\"will be added to warehouse and ready for packaging\"")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />

            {blue("class")} {green("AmazonProduct")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{blue("constructor")}{pink("(")}{babyblue("product")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("product")} = {babyblue("product")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />

            {blue("class")} {green("AmazonDeliveryBasic")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{blue("constructor")}{pink("(")}{babyblue("product")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("product")} = {babyblue("product")} + {string("\"with shipping: basic\"")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />

            {blue("class")} {green("AmazonDeliveryExpress")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{blue("constructor")}{pink("(")}{babyblue("product")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("product")} = {babyblue("product")} + {string("\"with shipping: express\"")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />

            {blue("class")} {green("PlaceOrder")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("product")} = {blue("null")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("packageType")} = {blue("null")};<br />

            &nbsp;&nbsp;&nbsp;&nbsp;{blue("constructor")}{pink("(")}{babyblue("product")}, {babyblue("packageType")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("product")} = {babyblue("product")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("packageType")} = {babyblue("packageType")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("order")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("packageType")} = {blue("new")} {green("AmazonProduct")}{blue("(this")}.{babyblue("packageType")}{blue(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("wareHouse")} = {blue("new")} {green("AmazonWarehouse")}{blue("(this")}.{babyblue("product")}{blue(")")};<br /><br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("switch")}{blue("(this")}.{babyblue("packageType")}{blue(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("case")} {string("\"basic\"")}: {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("let")} {babyblue("shippingPlane")} = {blue("new")} {green("AmazonDeliveryBasic")}{pink("(")}{blue("this")}.{babyblue("product")}{pink(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("return")} {blue("new")} {green("AmazonDeliveryBasic")}{pink("(")}{babyblue("shippingPlane")}{pink(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{yellow("}")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("break")};<br />

            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("case")} {string("\"express\"")}: {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("let")} {babyblue("shippingPlan")} = {blue("new")} {green("AmazonDeliveryExpress")}{pink("(")}{blue("this")}.{babyblue("product")}{pink(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("return")} {blue("new")} {green("AmazonDeliveryExpress")}{pink("(")}{babyblue("shippingPlane")}{pink(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{yellow("}")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("break")};<br />

            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("}")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />

            {blue("let")} {babyblue("product")} = {blue("new")} {green("PlaceOrder")}{yellow("(")}{string("\"MacBook M1 Chip\"")}, {string("\"express\"")}{yellow(")")};<br />
            {babyblue("product")}.{yellow("order")}{pink("()")};<br /><br />

            {red("Benefits:")} simplifies interface and encapsulates complexity, while improving performance through caching results.<br /><br />
            {red("Drawbacks:")} limited flexibility for the client and increased maintenance.
          </p>
        </details>

        <details>
          <summary style={{ fontSize: "20px" }}>Flyweight</summary>
          <p>

            The Flyweight pattern lets you fit more objects into the available amount of RAM by sharing common parts of state between multiple objects instead of keeping all of the data in each object.<br /><br />

            {red("Analogy:")} A band is doing a concert. Each band member has an instrument and some may use same type of instruments. If each member had to bring their own it would be difficult and expensive
            to transport. That is why they can share common resources such as instruments, like the flyweight pattern..<br /><br />

            {red("Participants:")} It involves 3 participant.
            <ul>
              <li>The FlyweightFactory Class</li>
              <li>The Flyweight Class</li>
              <li>The Context Class</li>
            </ul><br />

            {red("Process:")}
            <ul>
              <li>Create a Flyweight class containing portion of the original object's state that can be shared between multiple objects.</li>
              <li>The Context class contains the extrinsic state, unique across all original objects</li>
              <li>The FlyweightFactory class managed a pool of existing flyweights.</li>
            </ul><br />

            {red("Example:")}<br /><br />

            {blue("class")} {green("FlyweightFactory")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("flyweights")} = {blue("new")} {green("Map")}{pink("()")};<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("getFlyweight")}{pink("(")}{babyblue("name")}, {babyblue("health")}, {babyblue("damage")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("if")}{blue("(")}!{blue("this")}.{babyblue("flyweights")}.{yellow("has(")}{babyblue("name")}{yellow(")")}{blue(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("let")} {babyblue("flyweight")} = {blue("new")} {green("Flyweight")}{yellow("(")}{babyblue("name")}, {babyblue("health")}, {babyblue("damage")}{yellow(")")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("flyweights")}.{yellow("set(")}{babyblue("name")}, {babyblue("flyweight")}{yellow(")")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("return")} {blue("this")}.{babyblue("flyweights")}.{yellow("get")}{blue("(")}{babyblue("name")}{blue(")")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("}")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />

            {blue("class")} {green("Flyweight")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("damage")} = {lowGreen("0")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("name")} = {string("\"\"")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("health")} = {lowGreen("0")};<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{blue("constructor")}{pink("(")}{babyblue("name")}, {babyblue("health")}, {babyblue("damage")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("damage")} = {babyblue("damage")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("name")} = {babyblue("name")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("health")} = {babyblue("health")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{blue("}")}<br />
            {yellow("}")}<br /><br />

            {blue("class")} {green("ContextObject")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("x")} = {lowGreen("0")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("y")} = {lowGreen("0")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("type")} = {blue("null")};<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{blue("constructor")}{pink("(")}{babyblue("x")}, {babyblue("y")}, {babyblue("type")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("x")} = {babyblue("x")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("y")} = {babyblue("y")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("type")} = {babyblue("type")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{blue("}")}<br />
            {yellow("}")}<br /><br />

            {blue("let")} {babyblue("flyweightFactory")} = {blue("new")} {green("FlyweightFactory")}{yellow("()")}<br />
            {blue("let")} {babyblue("lowCharacter")} = {babyblue("flyweightFactory")}.{yellow("getFlyweight(")}{string("\"level1\"")}, {lowGreen("100")}, {lowGreen("25")}{yellow(")")};<br />
            {blue("let")} {babyblue("lowCharacter1")} = {babyblue("flyweightFactory")}.{yellow("getFlyweight(")}{string("\"level1\"")}, {lowGreen("100")}, {lowGreen("25")}{yellow(")")};<br /><br />

            {blue("let")} {babyblue("npc")} = {blue("new")} {green("ContextObject")}{yellow("(")}{lowGreen("20")}, {lowGreen("40")}, {babyblue("lowCharacter")}{yellow(")")};<br />
            {blue("let")} {babyblue("npc1")} = {blue("new")} {green("ContextObject")}{yellow("(")}{lowGreen("20")}, {lowGreen("40")}, {babyblue("lowCharacter1")}{yellow(")")};<br /><br />


            {red("Benefits:")} reduced memory usage by sharing common state among objects and performance by reducing number of objects needed to be created.<br /><br />
            {red("Drawbacks:")} limited applicability, increased complexity and decreased encapsulation.
          </p>
        </details>

        <details>
          <summary style={{ fontSize: "20px" }}>Proxy</summary>
          <p>
            The Proxy pattern lets you provide a substitute or a placeholder for another object. It controls access to the original object.<br /><br />

            Proxy, meaning "in place of", is a surrogate, handle or a wrapper. It lets you provide a substitute or placeholder for another object. A proxy holds access to the original object, allowing you to perform something
            either before or after the request gets through to the original object. In other words it controls and manages the access to the object it protects.<br /><br />

            There are multiple types of proxies:
            <ul>
              <li>{red("Virtual Proxy")} - it is used for lazy initialization. Instead of creating the object at start, you delay it till you actually need it.</li>
              <li>{red("Protection Proxy")} - it is used for access control. If the clients credential match, the proxy will delegate the request to the service.</li>
              <li>{red("Remote Proxy")} - it is used for local execution of a remote service. The proxy passes the client request over the network, handling all of the nasty details</li>
              <li>{red("Logging Proxy")} - it is used for storing client request history. It can implement caching for reccuring requests</li>
              <li>{red("Smart Reference Proxy")} - it is used for dismissing objects when inactive. If the client list gets empty, the proxy might dismiss the service object and free the underlying
                system resources.</li>
            </ul>

            {red("Analogy:")} Personal assistant which acts as a proxy for their employer. It takes care of tasks and responsibilities on their behalf. The personal assistant
            can control access to their employer.<br /><br />

            {red("Participants:")} It involves 3 participant.
            <ul>
              <li>Interface that both the Proxy and the Subject follow</li>
              <li>The Proxy Class</li>
              <li>The Real Subject Class</li>
            </ul><br />

            {red("Process:")}
            <ul>
              <li>Create an interface that both the proxy and the service class will follow as they need to be the same</li>
              <li>Create the service class that contains some useful business logic</li>
              <li>Create the proxy class that has a reference of the service object. After the proxy finishes its lazy initialization, loading, caching, it passes
                the request to the service object.
              </li>
            </ul><br />

            {red("Example:")}<br /><br />

            {blue("class")} {green("GetUserProfile")} {yellow("{")}<br /><br />
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("getUser")}{pink("(")}{babyblue("id")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("console")}.{yellow("log")}{blue("(")}{string("\"Gotten from get request user info for \"")} + {babyblue("id")}{blue(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />

            {blue("class")} {green("GetUserProfileProxy")} {yellow("{")}<br /><br />
            &nbsp;&nbsp;&nbsp;&nbsp;{gray("//check in storage if the requested id is already saved")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{gray("//so no need to re-request")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("userProfileObject")} = {blue("null")};<br /><br />
            &nbsp;&nbsp;&nbsp;&nbsp;{blue("constructor")}{pink("(")}{babyblue("realService")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("userProfileObject")} = {babyblue("realService")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("getUser")}{pink("(")}{babyblue("id")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("let")} {babyblue("user")} = {babyblue("window")}.{babyblue("localStorage")}.{yellow("getItem")}{blue("(")}{babyblue("id")}{blue(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("if")}{blue("(")}{babyblue("user")}{blue(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("return")} {babyblue("user")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("}")} {pink("else")} {blue("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("userProfileObject")}.{yellow("getUser(")}{babyblue("id")}{yellow(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("}")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />

            {blue("let")} {babyblue("user")} = {blue("new")} {green("GetUserProfile")}{yellow("()")};<br />
            {blue("let")} {babyblue("cachedUser")} = {blue("new")} {green("GetUserProfileProxy")}{yellow("(")}{babyblue("user")}{yellow(")")};<br /><br />

            {babyblue("cachedUser")}.{yellow("getUser(")}{lowGreen("2")}{yellow(")")};<br /><br />

            {red("Benefits:")} security as it adds aditional layer for access of the original objects and performance as object is created when needed.<br /><br />
            {red("Drawbacks:")} limited functionality as it cannot replace the original object entirely and inconsistent behavior as proxy object may differ from original.
          </p>
        </details>

        <h1 className='strike-through-text text-m' id="behavioral-id">Behavioral Design Patterns</h1>


        <details>
          <summary style={{ fontSize: "20px" }}>Chain of Responsibility</summary>
          <p>
            The Chain of Responsibility pattern lets you pass along requests along a chain of handlers. The job of the handlers is to either decide if the request should be processed or
            passed to the handler next in line.<br /><br />

            {red("Analogy:")} Imagine a customer has some issues with a product or service and he contacts customer support service. The customer support service can be
            seen as a chain of handler objects, where each handler solves specific type issue.<br /><br />

            {red("Participants:")} It involves 3 participant.
            <ul>
              <li>Interface that the Base Handler follows</li>
              <li>The Base Handler Class</li>
              <li>The Concrete Handler Class</li>
            </ul><br />

            {red("Process:")}
            <ul>
              <li>Declare an interface and what is the signature method for handling requests, in this examle it is the click() method.</li>
              <li>To eliminate duplicate boilerplate code in concrete handlers, create an abstract base handler class, derived from the handler interface</li>
              <li>Create conrete handler subclasses and implement their handling methods. The handlers should make two decisions, either process the request or
                pass it along the chain
              </li>
              <li>You can either assemble chains or pre-built chains from other objects.</li>
              <li>When calling a handler from the bottom of the chain, if it does not process the request, it passes it to the parents, until end of bubbling phase or capturing phase</li>
            </ul><br />

            {red("Example:")}<br /><br />

            {blue("class")} {green("Component")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("container")} = {blue("null")};<br /><br />
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("click")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("if")} {blue("(this")}.{babyblue("container")} == {blue("null) {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("container")}.{yellow("log(")}{string("\"End of bubbling phase\"")}{yellow(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("return")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("}")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}{babyblue("container")}.{yellow("click")}{blue("()")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />

            {blue("class")} {green("Container")} {blue("extends")} {green("Component")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("children")} = {pink("[]")};<br /><br />
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("add")}{pink("(")}{babyblue("child")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("children")}.{yellow("push")}{blue("(")}{babyblue("child")}{blue(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("child")}.{babyblue("container")}.{blue("this")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("click")}{pink("(")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("super")}.{yellow("click")}{blue("()")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />

            {blue("class")} {green("Div")} {blue("extends")} {green("Container")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("enableBubblingAndCapture")} = {blue("false")};<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("click")}{pink("(")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("console")}.{yellow("log")}{blue("(")}{string("\"Clicked on Div\"")}{blue(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("if")} {blue("(this")}.{babyblue("enableBubblingAndCapture")} == {blue("true) {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("super")}.{yellow("click")}{blue("()")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("}")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />

            {blue("class")} {green("Header")} {blue("extends")} {green("Container")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("enableBubblingAndCapture")} = {blue("false")};<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("click")}{pink("(")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("console")}.{yellow("log")}{blue("(")}{string("\"Clicked on Header\"")}{blue(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("if")} {blue("(this")}.{babyblue("enableBubblingAndCapture")} == {blue("true) {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("super")}.{yellow("click")}{blue("()")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("}")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />

            {blue("class")} {green("Span")} {blue("extends")} {green("Container")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("enableBubblingAndCapture")} = {blue("false")};<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("click")}{pink("(")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("console")}.{yellow("log")}{blue("(")}{string("\"Clicked on Span\"")}{blue(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("if")} {blue("(this")}.{babyblue("enableBubblingAndCapture")} == {blue("true) {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("super")}.{yellow("click")}{blue("()")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("}")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />

            {blue("let")} {babyblue("div")} = {blue("new")} {yellow("Div()")};<br />
            {blue("let")} {babyblue("h1")} = {blue("new")} {yellow("Header()")};<br />
            {blue("let")} {babyblue("span")} = {blue("new")} {yellow("Span()")};<br />

            {babyblue("h1")}.{yellow("add(")}{babyblue("span")}{yellow(")")};<br />
            {babyblue("div")}.{yellow("add(")}{babyblue("h1")}{yellow(")")};<br /><br />

            {babyblue("span")}.{babyblue("enableBubblingAndCapture")} = {blue("true")};<br />
            {babyblue("h1")}.{babyblue("enableBubblingAndCapture")} = {blue("true")};<br />
            {babyblue("div")}.{babyblue("enableBubblingAndCapture")} = {blue("true")};<br />
            {babyblue("span")}.{yellow("click()")}; <br />
            {gray("//Clicked on span")}<br />
            {gray("//Clicked on header")}<br />
            {gray("//Clicked on header")}<br />
            {gray("//End of bubbling phase")}<br /><br />

            {red("Benefits:")} scalability as it can handle increasing number of requests and decoupling as the sender does not know who handles the request.<br /><br />
            {red("Drawbacks:")} performance as a request will be passed to handlers before being handled and dependency on order.
          </p>
        </details>

        <details>
          <summary style={{ fontSize: "20px" }}>Command</summary>
          <p>
            The Command patters transforms your request in a request object containing all information to process the request. This transformation lets you pass requests
            as method arguments.<br /><br />

            The command pattern suggests that GUI objects shouldn't send requests directly. Instead, extract all details of the request, such as the object
            being called, the name of the method to be executed and the list of arguments. Command serve as links between GUI and business logic objects.<br /><br />

            {red("Analogy:")} In a restaurant a head chef gives order to a chef through series of written orders on paper what he needs to do.
            The head-chef is the client who creates the orders, while the chef is the invoker, the one executing. Each order writteon on the paper by
            the head chef is a command object in this case which encapsulates instruction or request e.g prepare the soup.<br /><br />

            {red("Participants:")} It involves 4 participant.
            <ul>
              <li>Sender class or also known as Invoker</li>
              <li>The Command Interface</li>
              <li>The Concrete Commands</li>
              <li>The Receiver class</li>
            </ul><br />

            {red("Process:")}
            <ul>
              <li>Declare an interface with execution command</li>
              <li>Create command classes that implement the command interface. Each class must send through the consctructor the actual receiver object.</li>
              <li>Identify classes that will act senders. Add the fields for storing commands in these clasees, for the purpose of undoing, redoing capabilites.
                The senders should only communicate with their commands only through the command interface.
              </li>
              <li>Change senders to execute the commands in order not to directly execute through the receiver</li>
              <li>The client needs to Create receivers, create commands and associate with receivers if neccessary and create senders.</li>
            </ul><br />

            {red("Example:")}<br /><br />

            {blue("class")} {green("ChangeVolumeCommand")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("tv")} = {blue("null")};<br /><br />
            &nbsp;&nbsp;&nbsp;&nbsp;{blue("constructor")}{pink("(")}{babyblue("tv")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("tv")} = {babyblue('tv')};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("execute")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("console")}.{yellow("log")}{blue("(")}{string("\"Execute Volume\"")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue('tv')}.{yellow("volumeUp")}{blue("()")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />

            {blue("class")} {green("ChangeChannelCommand")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("tv")} = {blue("null")};<br /><br />
            &nbsp;&nbsp;&nbsp;&nbsp;{blue("constructor")}{pink("(")}{babyblue("tv")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("tv")} = {babyblue('tv')};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("execute")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("console")}.{yellow("log")}{blue("(")}{string("\"Execute Channel\"")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue('tv')}.{yellow("channelNext")}{blue("()")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />

            {blue("class")} {green("TvRemote")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("execute")}{pink("(")}{babyblue("command")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue('command')}.{yellow("execute")}{blue("()")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />

            {blue("class")} {green("Tv")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("volumeUp")}{pink("()) {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("console")}.{yellow("log")}{blue("(")}{string("\"volume up\"")}{blue(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("channelNext")}{pink("()) {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("console")}.{yellow("log")}{blue("(")}{string("\"channel next\"")}{blue(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />

            {red("Benefits:")} enables implementation of undo/redo functionality, simplifies code since each command is its own class and enables composite commands to be executed
            in series of subcommands.<br /><br />

            {red("Drawbacks:")} increase in classes in the codebase, can be overkill for applications not requiring undo/redo functionality.

          </p>

        </details>

        <details>
          <summary style={{ fontSize: "20px" }}>Iterator</summary>
          <p>
            The Iterator pattern is a behavioral design pattern that lets you traverse elements of a collection without exposing its underlying representation (list, stack, tree, etc.).<br /><br />

            Collections are one of the most used data types. A collection is just a group of objects. If we were to traverse through a collection
            and we wanted to examine every branch from the root node before backtracking, we would use depth-first algorithm. But then, someday later
            we are in need of examining every branch related to the root and then continuing to the other leafs, this is the breadth-first algorithm. Adding more
            and more algorithms blurs its primary responsibility. <br /><br />

            {red("Analogy:")} A librarian who knows the layout of a library. If you were in a library and you wanted to get some book, instead of wandering around and
            hoping that you would find it, you could ask the librarian to help you. Unlike you, the librarian knows the layout and where each book could be stored. The book you
            wanted was listed online that it is in stock. The online catalog is the collection of items while the librarian is the iterator
            which traverses the collection and access each item in turn.<br /><br />

            {red("Participants:")} It involves 4 participant.
            <ul>
              <li>The Iterator Interface</li>
              <li>The Concrete Iterator Class</li>
              <li>The Aggregate Interface</li>
              <li>The Concrete Aggregate class</li>
            </ul><br />

            {red("Process:")}
            <ul>
              <li>Declare Iterator interface. It is suggested to contain a method for getting previous node, next in-line node, current element and whether it is the
                end of the iteration.
              </li>
              <li>Declare the collection interface (aggregate) and describe method for fetching iterators.</li>
              <li>Implement concrete iterator classes for the collections that you want to be traversable with iterators. An iterator must be linked with a single
                collection object. This is linked through iterator constructor.
              </li>
              <li>Implement the Concrete Collection (Concrete Aggregate) classes implementint the collection interface (aggregate)</li>
            </ul><br />

            {red("Example:")}<br /><br />

            {blue("class")} {green("Facebook")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("tree")} = {pink("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("name")}: {string("\"root\"")},<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("children")}: {blue("[")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("name")}: {string("\"node1\"")},<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{yellow("}")},<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("name")}: {string("\"node4\"")},<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("children")}: {pink("[")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("name")}: {string("\"node5\"")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("}")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("]")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{yellow("}")},<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("]")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />

            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("friends")} = {pink("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("name")}: {string("\"root\"")},<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("children")}: {blue("[")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("name")}: {string("\"friend1\"")},<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{yellow("}")},<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("name")}: {string("\"friend2\"")},<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("children")}: {pink("[")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("name")}: {string("\"mutualFriend1\"")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("}")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("]")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{yellow("}")},<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("]")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />

            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("jsonDataStructure")} = {blue("new")} {green("Map")}{pink("()")};<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{blue("constructor")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("jsonDataStructure")}.{yellow("set")}{blue("(")}{string("\"tree\"")}, {blue("this")}.{babyblue("tree")}{blue(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("jsonDataStructure")}.{yellow("set")}{blue("(")}{string("\"friends\"")}, {blue("this")}.{babyblue("friends")}{blue(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("PostOrderIterator")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("return")} {blue("new")} {yellow("PostOrderIterator")}{blue("(this")}, {string("\"tree\"")}{blue(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("PreOrderIterator")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("return")} {blue("new")} {yellow("PreOrderIterator")}{blue("(this")}, {string("\"friends\"")}{blue(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("getGraph")}{pink("(")}{babyblue("listToTraverse")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("return")} {blue("this")}.{babyblue("jsonDataStructure")}.{yellow("get")}{blue("(")}{babyblue("listToTraverse")}{blue(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />


            {blue("class")} {green("PreOrderIterator")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("stack")} = {pink("[]")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{blue("constructor")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("facebook")} = {babyblue("facebook")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("stack")}.{yellow("push")}{blue("(")}{babyblue("facebook")}.{yellow("getGraph(")}{babyblue("listToTraverse")}{yellow(")")}{blue(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("next")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("let")} {babyblue("current")} = {blue("this")}.{babyblue("stack")}.{yellow("pop")}{blue("()")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("if")}{blue("(")}{babyblue("current")}{blue(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{yellow("(")}{babyblue("current")}.{babyblue("children")} || {pink("[]")}{yellow(").reverse()")}.{yellow("forEach(")}{babyblue("child")}{blue("=> this")}.{babyblue("stack")}.{yellow("push")}{pink("(")}{babyblue("child")}{pink(")")}{yellow(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("return")} {yellow("{")}{babyblue("value")}: {babyblue("current")}, {babyblue("done")}: {blue("false")}{yellow("}")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("}")} {pink("else")} {blue("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("return")} {yellow("{")}{babyblue("done")}: {blue("true")}{yellow("}")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("}")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />
            {yellow("}")}<br /><br />

            {blue("class")} {green("PostOrderIterator")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("visited")} = {blue("new")} {green("Set")}{pink("()")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("stack")} = {pink("[]")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{blue("constructor")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("facebook")} = {babyblue("facebook")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("stack")}.{yellow("push")}{blue("(")}{babyblue("facebook")}.{yellow("getGraph(")}{babyblue("listToTraverse")}{yellow(")")}{blue(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("next")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("let")} {babyblue("current")} = {blue("this")}.{babyblue("stack")}{blue("[this")}.{babyblue("stack")}.{blue("length")} - {lowGreen("1")}{blue("]")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("while")} {blue("(")}{babyblue("current")} && {blue("this")}.{babyblue("visited")}.{yellow("has(")}{babyblue("current")}{yellow(")")}{blue(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("stack")}.{yellow("pop()")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("current")} = {blue("this")}.{babyblue("stack")}{yellow("[")}{blue("this")}.{babyblue("stack")}.{blue("length")} - {lowGreen("1")}{yellow("]")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("if")} {blue("(")}{babyblue("current")}{blue(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("visited")}.{yellow("add(")}{babyblue("current")}{yellow(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{yellow("(")}{babyblue("current")}.{babyblue("children")} || {pink("[]")}{yellow(")")}.{yellow("forEach(")}{babyblue("child")} {blue("=> this")}.{babyblue("stack")}.{yellow("push")}{pink("(")}{babyblue("child")}{pink(")")}{yellow(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("return")} {yellow("{")}{babyblue("value")}: {babyblue("current")}, {babyblue("done")}: {blue("false")}{yellow("}")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("}")} {pink("else")} {blue("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("return")} {yellow("{")} {babyblue("done")}: {blue("true")} {yellow("}")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("}")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />
            {yellow("}")}<br /><br />

            {blue("let")} {babyblue("facebook")} = {blue("new")} {green("Facebook")}{yellow("()")};<br />
            {blue("let")} {babyblue("iteratorTree")} = {babyblue("facebook")}.{yellow("PreOrderIterator()")};<br /><br />

            {babyblue("console")}.{yellow("log(")}{babyblue("iteratorTree")}.{yellow("next")}{pink("()")}.{babyblue("value")}{yellow(")")}; {gray("//{name: 'root', children: Array(2)}")}<br />
            {babyblue("console")}.{yellow("log(")}{babyblue("iteratorTree")}.{yellow("next")}{pink("()")}.{babyblue("value")}{yellow(")")}; {gray("//{name: 'friend1'}")}<br /><br />

            {blue("let")} {babyblue("iteratorFriends")} = {babyblue("facebook")}.{yellow("PostOrderIterator()")};<br /><br />

            {babyblue("console")}.{yellow("log(")}{babyblue("iteratorTree")}.{yellow("next")}{pink("()")}.{babyblue("value")}{yellow(")")}; {gray("//{name: 'root', children: Array(2)}")}<br />
            {babyblue("console")}.{yellow("log(")}{babyblue("iteratorTree")}.{yellow("next")}{pink("()")}.{babyblue("value")}{yellow(")")}; {gray("//{name: 'node4', children: Array(1)}")}<br />

            {red("Benefits:")} encapsulation of details making it easier to maintain and modify the code over time and separation of concerns.<br /><br />

            {red("Drawbacks:")} limited functionality as its meant to iterate collections in sequential manner not random access or other operations and complexity.
          </p>

        </details>

        <details>
          <summary style={{ fontSize: "20px" }}>Mediator</summary>
          <p>
            The Mediator pattern lets you reduce chaotic dependencies between objects and restricts direct communications between the objects and forces them to collaborate
            only via a mediator object.<br /><br />

            It suggests that no direct communication between objects should exist, but to collaborate indirectly by calling a special mediator object and all components
            are dependent only on one single class, instead of all with eachother. Usually the mediator object has references to all components they manage.<br /><br />

            {red("Analogy:")} You are working on some project. Each team-member has a different role in the project, but maybe sometime you have to coordinate or communicate.
            The central entity would be a team leader or project manager, who facilitates communication and coordinateion among the colleagues. Instead of each colleague to
            communicate directly with every other colleague, they communicate through the mediator.<br /><br />

            {red("Participants:")} It involves 4 participant.
            <ul>
              <li>The Mediator Interface</li>
              <li>The Concrete Mediator Class</li>
              <li>The Component Class</li>
            </ul><br />

            {red("Process:")}
            <ul>
              <li>Identify which classes will be dependent</li>
              <li>Declare a mediator interface, a single method for recieving notifications is sufficient</li>
              <li>Implement concrete mediator class. Consider storing references to all component inside the mediator. This way you will be able to call other components</li>
              <li>(OPTIONAL): You could make the mediator responsible for creation and destruction of component objects</li>
              <li>Components should store a reference to the mediator object, usually through constructor</li>
              <li>Change the components code so that they call the mediators notification method instead on other components.</li>
            </ul><br />

            {red("Example:")}<br /><br />

            {blue("class")} {green("Boss")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("notify")}{pink("(")}{babyblue("sender")}, {babyblue("event")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("if")} {blue("(")}{babyblue("sender")} {blue("instanceof")} {green("Manager")} && {babyblue("event")} == {string("\"salary\"")}{blue(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("sender")}.{babyblue("salary")} += {lowGreen("500")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("console")}.{yellow("log(")}{string("`Employee")} {blue("${")}{babyblue("sender")}.{yellow("getName")}{pink("()")}{blue(")")} {string("has gotten salary of")} {blue("${")}{babyblue("sender")}.{babyblue("salary")}{blue(")")}`{yellow(")")}`;<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("return")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("}")} {pink("else if")}{blue("(")}{babyblue("sender")} {blue("instanceof")} {green("Cashier")} && {babyblue("event")} == {string("\"salary\"")}{blue(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("sender")}.{babyblue("salary")} += {lowGreen("300")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("console")}.{yellow("log(")}{string("`Employee")} {blue("${")}{babyblue("sender")}.{yellow("getName")}{pink("()")}{blue(")")} {string("has gotten salary of")} {blue("${")}{babyblue("sender")}.{babyblue("salary")}{blue(")")}`{yellow(")")}`;<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("return")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("if")} {blue("(")}{babyblue("event")} == {string("\"end\"")}{blue(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("sender")}.{babyblue("onShift")} = {blue("true")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("console")}.{yellow("log(")}{string("`Employee")} {blue("${")}{babyblue("sender")}.{yellow("getName")}{pink("()")}{blue(")")} {string("has ended shift`")}{yellow(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("if")} {blue("(")}{babyblue("event")} == {string("\"manage\"")}{blue(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("console")}.{yellow("log(")}{string("`Employee")} {blue("${")}{babyblue("sender")}.{yellow("getName")}{pink("()")}{blue(")")} {string("has managed employees`")}{yellow(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")};<br /><br />

            {blue("class")} {green("User")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("mediator")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("name")} = {string("\"\"")};<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{blue("constructor")}{pink("(")}{babyblue("name")}, {babyblue("mediator")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("mediator")} = {babyblue("mediator")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("name")} = {babyblue("name")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("getSalary")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("mediator")}.{yellow("notify")}{blue("(this")}, {string("salary")}{blue(")")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("getName")}{pink("(){")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("return")} {babyblue("name")};
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />

            {blue("class")} {green("Manager")} {blue("extends")} {green("User")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("salary")} = {lowGreen("0")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("manageEmloyees")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("mediator")}.{yellow("notify")}{blue("(this")}, {string("\"manage\"")}{blue(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />

            {blue("class")} {green("Cashier")} {blue("extends")} {green("User")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("salary")} = {lowGreen("0")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("onShift")} = {blue("true")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("endShift")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("mediator")}.{yellow("notify")}{blue("(this")}, {string("\"end\"")}{blue(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />

            {blue("let")} {babyblue("boss")} = {blue("new")} {green("Boss")}{yellow("()")};<br />
            {blue("let")} {babyblue("manager")} = {blue("new")} {green("Manager")}{yellow("(")}{string("\"Joanis\"")}, {babyblue("boss")}{yellow(")")};<br />
            {blue("let")} {babyblue("cashier")} = {blue("new")} {green("Cashier")}{yellow("(")}{string("\"John\"")}, {babyblue("boss")}{yellow(")")};<br /><br />

            {babyblue("manager")}.{yellow("getSalary()")};<br />
            {babyblue("cashier")}.{yellow("endShift()")};<br /><br />

            {red("Benefits:")} reduced coupling by introducing mediator between objects and encapsulated behavior.<br /><br />

            {red("Drawbacks:")} increased complexity by introducing another layer of abstraction, dependency on the mediator which can make the system more difficult
            and increased communication between objects.
          </p>

        </details>

        <details>
          <summary style={{ fontSize: "20px" }}>Memento</summary>
          <p>
            The Memento pattern lets you save and restore the previous state of an object without revealing the details of its implementation.<br /><br />

            It suggests storing the object's state in a special object called memento, while the original object is called an originator. The memento pattern
            delegates creating the state snapshots to the actual owner of that state, the originator object. The originator class can save state since it has
            full access to ts own state.<br /><br />

            {red("Analogy:")} Imagine you are writing code and want to undo your mistake, as a programmer you would instantly click <kbd>CTRL + Z</kbd>. The
            same applies to the memento pattern, imagine you change some state of an object, but to be sure and safe, just before you change it you save it
            in order to be able to undo if the outcome is not what you hoped for.<br /><br />

            {red("Participants:")} It involves 3 participant.
            <ul>
              <li>The Originator Class</li>
              <li>The Memento Class</li>
              <li>The Caretaker Class</li>
              <li>(OPTIONAL) Originator Interface</li>
              <li>(OPTIONAL) Memento Interface</li>
            </ul><br />

            {red("Process:")}
            <ul>
              <li>Make an orignator class with original or updated properties. It has a method for saving its state inside a memento and another method for restoring the state from it</li>
              <li>Make a memento class which stores the past state of the editor</li>
              <li>A command object that acts as a caretaker. The command gets a memento just before it changes the originator's state. When undo is requested, it
                requested, it restores the origniator's state from a memento.
              </li>
            </ul><br />

            {red("Example:")}<br /><br />

            {blue("class")} {green("Originator")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("#clipboardText")} = {string("\"Before edit!\"")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("setText")}{pink("(")}{babyblue("text")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("#clipboardText")} = {babyblue("text")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("createSnapshot")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("return")} {blue("new")} {yellow("Memento")}{blue("(this")}.{babyblue("#clipboardText")}, {blue("this)")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />
            {yellow("}")}<br /><br />

            {blue("class")} {green("Memento")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("#originatorInstance")} = {blue("null")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("#clipboardText")};<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{blue("constructor")}{pink("(")}{babyblue("clipboardText")}, {babyblue("originator")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("#clipboardText")} = {babyblue("clipboardText")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("#originatorInstance")} = {babyblue("originator")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("restore")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("#originatorInstance")}.{yellow("setText")}{blue("this")}.{babyblue("#clipboardText")}{blue(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />
            {yellow("}")}<br /><br />

            {blue("let")} {babyblue("originator")} = {blue("new")} {yellow("Originator()")};<br />
            {blue("let")} {babyblue("backup")} =  {babyblue("originator")}.{yellow("createSnapshot()")};<br /><br />

            {babyblue("originator")}.{yellow("setText(")}{string("\"After edit!\"")}{yellow(")")};<br />
            {babyblue("backup")}.{yellow("restore()")}<br /><br />

            {babyblue("console")}.{yellow("log(")}{babyblue("originator")}{yellow(")")};<br /><br />

            {red("Benefits:")} implementation of undo/redo funcionality, supports open/closed principle and encapsulates the state of object.<br /><br />

            {red("Drawbacks:")} can use a lot of memory if too many saved states, reduce of performance due to overhead of saving and restoring state of object.
          </p>

        </details>

        <details>
          <summary style={{ fontSize: "20px" }}>Observer</summary>
          <p>
            The Observer pattern gives us the possibility to react to changes or just receive notification that some change took place to something that we decided to "watch" or be "notified" to.
            <br /><br />
            The observer pattern consists of a subject, a publisher and a subscriber.<br />
            A subject is the object that contains some interesting state, and since it will notify other objects of changes, it also takes the role of a publisher. Subscribers are
            all objects that are "watching" the subject for changes.<br /><br />

            {red("Analogy:")} If you were curious about any weather changes you would check the weather station. You (observer) are interested in
            weather changes that might occur and the weather station (publisher) notifies you<br /><br />

            {red("Participants:")} It involves 4 participant.
            <ul>
              <li>The Publisher Interface</li>
              <li>The Observer Interface</li>
              <li>The Concrete Publisher Class</li>
              <li>The Concrete Observer Class</li>
            </ul><br />

            {red("Process:")}
            <ul>
              <li>Break business logic into two parts: publisher and subscriber(s)</li>
              <li>Declare and Create your subscriber class, consisting of atleast an update method</li>
              <li>Store the subscribers list in a concrete publisher class with subscribe, unsubscribe and update methods as code logic</li>
              <li>Implement update method for updating subscribers based on the update event e.g click, scroll, hover</li>
              <li>The client does the registering of all subscribers to proper publishers.</li>
            </ul><br />

            {red("Example:")}<br /><br />

            {blue("class")} {green("EventManager")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("#listeners")} = {blue("new")} {green("Map")}{pink("()")}<br /><br />
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("subscribe")}{pink("(")}{babyblue("event")}, {babyblue("listener")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("#listeners")}.{yellow("set")}{blue("(")}{babyblue("event")}, {babyblue("listener")}{blue(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("unsubscribe")}{pink("(")}{babyblue("event")}, {babyblue("listener")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("#listeners")}.{yellow("delete")}{blue("(")}{babyblue("event")}{blue(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("notify")}{pink("(")}{babyblue("event")}, {babyblue("data")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("for")}{blue("(let ")}{babyblue("listener")} {blue("of this")}.{babyblue("#listeners")}.{yellow("entries()")}{blue(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("listener")}{yellow("[")}{lowGreen("1")}{yellow("]")}.{yellow("update(")}{babyblue("listener")}{pink("[")}{lowGreen("0")}{pink("]")}, {babyblue("data")}{yellow(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("}")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />

            {blue("class")} {green("Editor")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("events")};<br /><br />
            &nbsp;&nbsp;&nbsp;&nbsp;{blue("constructor")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("events")} = {blue("new")} {green("EventManager")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("clickOnHome")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("events")}.{yellow("notify")}{blue("(")}{string("\"click\"")}, {string("\"home\"")}{blue(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("clickOnAbout")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("events")}.{yellow("notify")}{blue("(")}{string("\"click\"")}, {string("\"about\"")}{blue(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />
            {yellow("}")}<br /><br />

            {blue("class")} {green("DomListener")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("update")}{pink("(")}{babyblue("event")}, {babyblue("data")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("console")}.{yellow("log")}{blue("(")}{string("\"DOM DETECTED A \"")} + {babyblue("event")} + {string("\" on\"")}, {babyblue("data")}{blue(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />

            {blue("let")} {babyblue("editor")} = {blue("new")} {green("Editor")}{yellow("()")};<br />
            {blue("let")} {babyblue("listener1")} = {blue("new")} {green("DomListener")}{yellow("()")};<br />
            {blue("let")} {babyblue("listener2")} = {blue("new")} {green("DomListener")}{yellow("()")};<br /><br />
            {babyblue("editor")}.{babyblue("events")}.{yellow("subscribe(")}{string("\"click\"")}, {babyblue("listener1")}{yellow(")")};<br />
            {babyblue("editor")}.{babyblue("events")}.{yellow("subscribe(")}{string("\"click\"")}, {babyblue("listener2")}{yellow(")")};<br /><br />
            {babyblue("editor")}.{yellow("clickOnHome()")};<br />
            {babyblue("editor")}.{yellow("clickOnAbout()")};<br /><br />

            {red("Benefits:")} loose coupling, scalability and reusability.<br /><br />

            {red("Drawbacks:")} memory leaks if observers not removed and ordering of notifications.
          </p>
        </details>

        <details>
          <summary style={{ fontSize: "20px" }}>State</summary>
          <p>
            The State pattern is a great pattern when you need to manage behaviour on some object in order to behave differently.<br /><br />

            It suggests creating new classes for all possible states of an object and extract all state-specific behaviors into these classes.<br /><br />

            {red("Analogy:")} If you were driving somewhere, you would have to atleast cross paths with one traffic light. The traffic light has
            three states: green, yellow and red. Depending on the state of the traffic light, the behavior of the cars at the intersection changes.<br /><br />

            {red("Participants:")} It involves 3 participant.
            <ul>
              <li>The Context Class</li>
              <li>The State Interface</li>
              <li>The Concrete States Class</li>
            </ul><br />

            {red("Process:")}
            <ul>
              <li>Decide what class will act as context. This means a class that will define set of actions you can take, methods to invoke, variables to use. It
                encapsulates the references/pointers needed for other objects.
              </li>
              <li>Declare state interface and the state classes that will use this interface.</li>
              <li>In context class add reference filed for the state class and a public setter for changing the state and the methods to be called, the methods
                logic will differ depending on the state the object is currently on.
              </li>
            </ul><br />

            {red("Example:")}<br /><br />

            {blue("class")} {green("VideoPlayer")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("state")} = {blue("null")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{blue("constructor")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("state")} = {blue("new")} {green("ReadyState")}{blue("(this)")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("play")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("state")}.{yellow("play")}{blue("()")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("changeState")}{pink("(")}{babyblue("newState")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("state")} = {babyblue("newState")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />

            {blue("class")} {green("State")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("player")} = {blue("null")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{blue("constructor")}{pink("(")}{babyblue("player")}{(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("player")} = {babyblue("player")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />

            {blue("class")} {green("ReadyState")} {blue("extends")} {green("State")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("play")}{("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("player")}.{yellow("changeState")}{blue("(new")} {yellow("PlayingState(")}{blue("this")}.{babyblue("player")}{blue(")")}{yellow("}")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("console")}.{yellow("log")}{blue("(")}{string("\"home\"")}{blue(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />

            {blue("class")} {green("LockedState")} {blue("extends")} {green("State")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("play")}{("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("console")}.{yellow("log")}{blue("(")}{string("\"Do nothing, video player is locked\"")}{blue(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />

            {blue("class")} {green("PlayingState")} {blue("extends")} {green("State")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("play")}{("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("console")}.{yellow("log")}{blue("(")}{string("\"Stop video\"")}{blue(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />

            {blue("let")} {babyblue("videoPlayer")} = {blue("new")} {yellow("VideoPlayer()")};<br />
            {babyblue("videoPlayer")}.{yellow("play()")}; {gray("//Play video")}<br />
            {babyblue("videoPlayer")}.{yellow("play()")};{gray("//Stop video")}<br />
            {babyblue("videoPlayer")}.{yellow("changeState(")}{blue("new")} {yellow("LockedState")}{pink("(")}{babyblue("videoPlayer")}{pink(")")}{yellow(")")};<br />
            {babyblue("videoPlayer")}.{yellow("play()")};{gray("//Do nothing, video player is locked")}<br /><br />

            {red("Benefits:")} loose coupling, scalability and reusability.<br /><br />

            {red("Drawbacks:")} memory leaks if observers not removed and ordering of notifications.
          </p>
        </details>

        <details>
          <summary style={{ fontSize: "20px" }}>Strategy</summary>
          <p>
            The Strategy pattern lets you define a family of algorithms and when to use each of of them. Each of the algorithm is in its own class.<br /><br />

            Each class containing an algorithm is called a strategy. The original class is called a context, it has a field for storing a reference of one strategy, and you pass
            the strategy you want to use. The context afterwards delegates the work to the linked strategy objects instead of executing on its own.<br /><br />

            {red("Analogy:")} A chef preparing a meal. The ingredients of the meal can vary depending on the preferences of the customer. If the customer
            wants a vegetarian dish, the chef can use a vegetarian recipe as the strategy to prepare the meal.<br /><br />

            {red("Participants:")} It involves 3 participant.
            <ul>
              <li>The Context Class</li>
              <li>The Strategy Interface</li>
              <li>The Concrete Strategy</li>
            </ul><br />

            {red("Process:")}
            <ul>
              <li>Create a context class with a field for reference of a strategy class</li>
              <li>Create the needed strategies and put each of them in their own separate class with same interface</li>
              <li>Provide a setter in the context class for changing the strategy and delegate work to that strategy class.</li>
            </ul><br />

            {red("Example:")}<br /><br />

            {blue("class")} {green("StrategyAdd")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("execute")}{pink("(")}{babyblue("a")}, {babyblue("b")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("return")} {babyblue("a")} + {babyblue("b")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />

            {blue("class")} {green("StrategySubstract")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("execute")}{pink("(")}{babyblue("a")}, {babyblue("b")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("return")} {babyblue("a")} - {babyblue("b")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />

            {blue("class")} {green("StrategyMultiply")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("execute")}{pink("(")}{babyblue("a")}, {babyblue("b")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("return")} {babyblue("a")} * {babyblue("b")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />

            {blue("class")} {green("StrategyMultiply")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("strategy")} = {blue("null")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("setStrategy")}{pink("(")}{babyblue("strategy")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("strategy")} = {babyblue("strategy")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("executeStrategy")}{pink("(")}{babyblue("a")}, {babyblue("b")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("return")} {blue("this")}.{babyblue("strategy")}.{yellow("execute")}{blue("(")}{babyblue("a")} , {babyblue("b")}{blue(")")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />

            {blue("let")} {babyblue("context")} = {blue("new")} {yellow("Context()")}<br />
            {babyblue("context")}.{yellow("setStrategy(")}{blue("new")} {yellow("StrategyAdd")}{pink("()")}{yellow(")")}<br /><br />

            {blue("let")} {babyblue("result")} = {babyblue("context")}.{yellow("executeStrategy(")}{lowGreen("1")}, {lowGreen("5")}{yellow(")")}<br />
            {babyblue("console")}.{yellow("log(")}{babyblue("result")}{yellow(")")}; {gray("//6")}<br /><br />

            {red("Benefits:")} allows easy modification at runtime, promotes code reusability and facilitates testing.<br /><br />

            {red("Drawbacks:")} increased complexity, slower performance by runtime modifications and can be overused..
          </p>
        </details>

        <details>
          <summary style={{ fontSize: "20px" }}>Template Method</summary>
          <p>
            The Template Method pattern allows you to define the skeleton/structure of an object that other classes will
            extend it and override certain steps, properties, methods needed to that object.<br /><br />

            {red("Analogy:")} A consctruction project. Each construction project has a plan that outlines the steps taken to complete the project.
            The construction of a house, bridge or skyscraper may require different materials, but all follow similar framework.<br /><br />

            {red("Participants:")} It involves 2 participant.
            <ul>
              <li>The Abstract Class</li>
              <li>The Concrete Class</li>
            </ul><br />

            {red("Process:")}
            <ul>
              <li>Define a class where its properties and methods will be common to all subclasses and which ones will always be unique, overriding the default value.</li>
              <li>For each class create new concrete subsclass. It then overrides or uses some of the methods optionally.</li>
            </ul><br />

            {red("Example:")}<br /><br />

            {blue("class")} {green("Book")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{blue("constructor")}{pink("(")}{babyblue("book")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("pages")} = {babyblue("book")}.{babyblue("pages")} || {lowGreen("100")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("price")} = {babyblue("book")}.{babyblue("price")} || {lowGreen("9.99")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("title")} = {babyblue("book")}.{babyblue("title")} || {string("\"Un-titled release\"")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("addPages")}{pink("(")}{babyblue("number")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("pages")} += {babyblue("number")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("addDiscount")}{pink("(")}{babyblue("discount")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("price")} = {blue("(this")}.{babyblue("price")} - {yellow("(")}{blue("this")}.{babyblue("price")} * {babyblue("discount")} / {lowGreen("100")}{yellow(")")}{blue(")")}.{yellow("toFixed")}{blue("(")}{lowGreen("2")}{blue(")")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("getInfo")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("console")}.{yellow("log")}{blue("(")}{string("`The book")} {blue("$")}{yellow("{")}{blue("this")}.{babyblue("title")}{yellow("}")} {string("with")} {blue("$")}{yellow("{")}{blue("this")}.{babyblue("pages")} {string("is")} {blue("$")}{yellow("{")}{blue("this")}.{babyblue("price")}{yellow("}")}$`{blue(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />

            {blue("class")} {green("RomeoAndJuliet")} {blue("extends")} {green("Book")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("sale")} = {blue("false")};<br /><br />
            &nbsp;&nbsp;&nbsp;&nbsp;{blue("constructor")}{pink("(")}{babyblue("book")}, {babyblue("sale")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("super(")}{babyblue("book")}{blue(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("sale")} = {babyblue("sale")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("checkForDiscount")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("if")}{blue("this")}.{babyblue("sale")}{blue(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("super")}.{yellow("addDiscount(")}{lowGreen("10")}{yellow(")")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("super")}.{yellow("getInfo()")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("}")}{pink("else")} {blue("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("super")}.{yellow("getInfo()")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("}")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />

            {blue("class")} {green("WinFriendsAndInfluencePeople")} {blue("extends")} {green("Book")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("sale")} = {blue("false")};<br /><br />
            &nbsp;&nbsp;&nbsp;&nbsp;{blue("constructor")}{pink("(")}{babyblue("book")}, {babyblue("sale")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("super(")}{babyblue("book")}{blue(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("super")}.{yellow("addPages")}{blue("(")}{lowGreen("18")}{blue(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("sale")} = {babyblue("sale")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("checkForDiscount")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("if")}{blue("this")}.{babyblue("sale")}{blue(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("super")}.{yellow("addDiscount(")}{lowGreen("45")}{yellow(")")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("super")}.{yellow("getInfo()")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("}")}{pink("else")} {blue("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("super")}.{yellow("getInfo()")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("}")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br />
            {yellow("}")}<br /><br />

            {blue("let")} {babyblue("romeoAndJuliet")} = {blue("new")} {yellow("RomeoAndJuliet(")}{pink("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("pages")}: {lowGreen("156")},<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("price")}: {lowGreen("12.99")},<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("title")}: {string("\"Romeo and Juliet\"")},<br />
            {pink("}")}, {blue("false")}{yellow(")")};<br />
            {babyblue("romeoAndJuliet")}.{yellow("checkForDiscount()")}; {gray("//The book Romeo and Juliet with 156 pages is 12.99$")} <br /><br />

            {blue("let")} {babyblue("winFriendsAndInfluencePeople")} = {blue("new")} {yellow("WinFriendsAndInfluencePeople(")}{pink("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("pages")}: {lowGreen("214")},<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("price")}: {lowGreen("19.99")},<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("title")}: {string("\"How to win friends and influence people\"")},<br />
            {pink("}")}, {blue("false")}{yellow(")")};<br />
            {babyblue("winFriendsAndInfluencePeople")}.{yellow("checkForDiscount()")}; {gray("//The book How to win friends and influence people with 232 pages is 10.99$")}<br /><br />

            {red("Benefits:")} provides a framework for implementing an algorithm that can be easily extended, facilitates testing and allows for customization of specific steps.<br /><br />

            {red("Drawbacks:")} can lead to rigid code structure if not designed carefully, may result in a large number of subclasses and may result in performance overhead.</p>
        </details>

        <details>
          <summary style={{ fontSize: "20px" }}>Visitor</summary>
          <p>
            The Visitor pattern lets you separate algorithms from objects on which they operate<br /><br />

            This pattern suggests that you place the new behavior into a separate class called visitor, instead of trying to integrate in existing classes. As the name suggests, it visits
            all of the objects from some specific class and executes some method accordingly<br /><br />

            Some people might say, "why not use method overloading"? - when methods have same name but different parameters. If we do a method overload and as parameter we specify a class object it won't
            execute right. Reason is that the exact class of a node object is unknown in advance, so the overloading method won't work and will execute the first method in the line.<br />
            The visitor pattern uses a technique called Double Dispatch which addresses this problem. Instead of the client selecting a proper version of the method to call, we delegate that task to the visitor.
            The concrete classes then send themselves using the keyword "this" meaning there is no chance possible to mistake which class it came from.<br /><br />

            {red("Analogy:")} Museum tour guide takes visitors on a tour of different exhibits, the visitor pattern separates algorithm from traversing an object
            structure from the objects themselves. The visitor provides a way to visit each object in the structure and perform a specific action on it, just like tour
            guide provides information and context about each exhibit.<br /><br />

            {red("Participants:")} It involves 4 participant.
            <ul>
              <li>The Visitor Interface</li>
              <li>The Concrete Visitors Class</li>
              <li>The Element Interface</li>
              <li>The Concrete Element Class</li>
            </ul><br />

            {red("Process:")}
            <ul>
              <li>Declare classes that will be used within the visitor via the accept method.</li>
              <li>Declare the visitor class with a set of "visiting" methods, one per each concrete element</li>
              <li>Pass the instance from a class to the visiting method</li>
            </ul><br />

            {red("Example:")}<br /><br />

            {blue("class")} {green("Book")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("title")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("price")};<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{blue("constructor")}{pink("(")}{babyblue("title")}, {babyblue("price")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("title")} = {babyblue("title")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("price")} = {babyblue("price")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("getTitle")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("return")} {blue("this")}.{babyblue("title")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("getPrice")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("return")} {blue("this")}.{babyblue("price")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("accept")}{pink("(")}{babyblue("visitor")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("visitor")}.{yellow("visitBook")}{blue("(this)")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />
            {yellow("}")}<br /><br />

            {blue("class")} {green("Magazine")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("name")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("numPages")};<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{blue("constructor")}{pink("(")}{babyblue("name")}, {babyblue("numPages")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("name")} = {babyblue("name")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("numPages")} = {babyblue("numPages")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("getName")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("return")} {blue("this")}.{babyblue("name")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("getNumPages")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("return")} {blue("this")}.{babyblue("numPages")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("accept")}{pink("(")}{babyblue("visitor")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{babyblue("visitor")}.{yellow("visitMagazine")}{blue("(this)")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />
            {yellow("}")}<br /><br />

            {blue("class")} {green("ShoppingCartVisitor")} {yellow("{")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{babyblue("totalPrice")} = {lowGreen("0")};<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("visitBook")}{pink("(")}{babyblue("book")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("totalPrice")} += {babyblue("book")}.{yellow("getPrice")}{blue("()")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("visitMagazine")}{pink("(")}{babyblue("magazine")}{pink(") {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue("this")}.{babyblue("totalPrice")} += {blue("(")}{babyblue("book")}.{yellow("getNumPages()")} * {lowGreen("0.02")}{blue(")")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />

            &nbsp;&nbsp;&nbsp;&nbsp;{yellow("getTotalPrice")}{pink("() {")}<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("return")} {blue("this")}.{babyblue("totalPrice")};<br />
            &nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br /><br />
            {yellow("}")}<br /><br />


            {red("Benefits:")} provides a framework for implementing an algorithm that can be easily extended, facilitates testing and allows for customization of specific steps.<br /><br />

            {red("Drawbacks:")} can lead to rigid code structure if not designed carefully, may result in a large number of subclasses and may result in performance overhead.</p>

        </details>
      </>
    },
    {
      links: [
        {
          title: "Introduction",
          to: "introduction"
        },
        {
          title: "Binary System",
          to: "binary-system"
        },
        {
          title: "Conversion from Binary to Decimal",
          to: "conversion-binary-to-decimal"
        },
        {
          title: "Conversion from Decimal to Binary",
          to: "conversion-decimal-to-binary"
        },
        {
          title: "Binary Operations",
          to: "binary-operations"
        },
        {
          title: "Binary Logic",
          to: "binary-logic"
        },
        {
          title: "Data Storage",
          to: "data-storage"
        },
        {
          title: "Hexadecimal System",
          to: "hexadecimal-system"
        },
        {
          title: "Conversion from Binary to Hexadecimal",
          to: "conversion-binary-to-hexadecimal"
        },
        {
          title: "Conversion from Hexadecimal to Binary",
          to: "conversion-hexadecimal-to-binary"
        },
        {
          title: "Bitwise",
          to: "bitwise"
        },
        {
          title: "Data Types",
          to: "data-types"
        },
      ],
      title: "Binary System",
      route: "binary-system",
      published: "Published: 04/12/2002",
      book:
        <>
          <h1 className='strike-through-text text-m' id="introduction-id">Introduction</h1>
          <p>
            The {red("binary")} system is a system that only uses {lowGreen("2")} numbers, {lowGreen("0")} and {lowGreen("1")}. The {lowGreen("0")} represents off and the {lowGreen("1")} represents on, how?
          </p>
          <p>
            A computer is composed of billions of transistors and a transistor only has {lowGreen("2")} states of electricity, ON and OFF. They are stored in a CPU chip and they all
            work together where simultaneously some turn on and some turn off, outputing different tasks and commands which we can use.
          </p>
          <p>
            {red("Gottfried Wilhelm Leibniz")} ({lowGreen("1646-1716")}) is the self-proclaimed inventor of the binary system and is considered as such by most historians of mathematics and/or mathematicians.
          </p>
          <h1 className='strike-through-text text-m' id="binary-system-id">Binary System</h1>
          <p>
            In binary each number is called a {red("bit")} which is short for {red("binary digit")}. A sequence of 8 {red("bits")} is known as a {red("byte")}
          </p>
          <p>
            We humans use the deca system where {red("deca")} translates to {lowGreen("10")}, it signifies that the system is a power of {lowGreen("10")}. The {red("bi")} in binary signifies that it
            uses a power of {lowGreen("2")}.
          </p>
          <p>
            The reason why there are things like {lowGreen("32")}bit operating system, {lowGreen("64")}bit operating system or {lowGreen("512")}gygabytes it is because they are all deviations,
            exponentials of {lowGreen("2")}. So if we now have {lowGreen("512")}gigabytes of something, next year we have {lowGreen("1024")} that's only because they discovered how to add another
            {lowGreen("0")} to the end of it.
          </p>
          <p>
            {red("Different systems for different purposes:")}
            <ul>
              <li>
                RAM uses the Hexadecimal system only because it can represent large numbers by using exponents.
              </li>
              <li>
               Binary is used for all digital computations and data storage within a computer, including arithmetic operations, logic gates, memory addressing, and encoding of instructions and data.
              </li>
            </ul>

            {red("For representing for example the number")} {lowGreen("8")}
            <ul>
              <li>
                Hexa it will take one place - {lowGreen("8")}
              </li>
              <li>
                Deca it will take one place - {lowGreen("8")}
              </li>
              <li>
                Binary it will take four places - {lowGreen("1000")}
              </li>
            </ul>
            {red("If")} we could make analog computers - having more than {lowGreen("2")} states, we would but we can't do that with our current technology so we use the binary system.
          </p>
          <p>
            So, having said everything until now. We can gather that every number is represented in a combination of 0's and 1's. For example:
            <ul>
              <li>
                The Binary Number {lowGreen("1010")} represents {lowGreen("10")} decimal in the deca system.
              </li>
              <li>
                The Decimal Number {lowGreen("10")} represents {lowGreen("1010")} binary number in the binary system.
              </li>
            </ul>
          </p>
          <p>{red("Note:")}: the rightmost bit is the {red("least significant bit (LSB)")}, while the leftmost bit is the {red("most significant bit (MSB)")}.</p>

          <h1 className='strike-through-text text-m' id="conversion-binary-to-decimal-id">Conversion from Binary to Decimal</h1>
          <p>
            Supose we want to convert the binary number 101011 to decimal:
            <ol>
              <li>Write down the binary number.</li>
              <li>Assign a place value to each digit.
                Start from the rightmost digit and assign a place value of {lowGreen("2")}^{lowGreen("0")} ({lowGreen("1")}) to the first digit. 
                Each subsequent digit's place value doubles the previous one: {lowGreen("2")}^{lowGreen("1")} ({lowGreen("2")}), {lowGreen("2")}^{lowGreen("2")} ({lowGreen("4")}), 
                {lowGreen("2")}^{lowGreen("3")} ({lowGreen("8")}), and so on.
                In our example, the place values are: {lowGreen("2")}^{lowGreen("5")}, {lowGreen("2")}^{lowGreen("4")}, {lowGreen("2")}^{lowGreen("3")}, {lowGreen("2")}^{lowGreen("2")},
                {lowGreen("2")}^{lowGreen("1")}, and {lowGreen("2")}^{lowGreen("0")}.</li>
              <li>Multiply each digit by its corresponding place value.
                Multiply each digit of the binary number by its corresponding place value determined in the previous step.
                In our example: {lowGreen("1")} * {lowGreen("2")}^{lowGreen("5")} + {lowGreen("0")} * {lowGreen("2")}^{lowGreen("4")} + {lowGreen("1")} * {lowGreen("2")}^{lowGreen("3")}
                 + {lowGreen("0")} * {lowGreen("2")}^{lowGreen("2")} + {lowGreen("1")} * {lowGreen("2")}^{lowGreen("1")} + {lowGreen("1")} * {lowGreen("2")}^{lowGreen("0")}.</li>
              <li>Sum up the results from the previous step.
                Calculate the result of each multiplication and sum them up to get the decimal equivalent of the binary number.
                In our example: ({lowGreen("1")} * {lowGreen("32")}) + ({lowGreen("0")} * {lowGreen("16")}) + ({lowGreen("1")} * {lowGreen("8")}) 
                + ({lowGreen("0")} * {lowGreen("4")}) + ({lowGreen("1")} * {lowGreen("2")}) + ({lowGreen("1")} * {lowGreen("1")}) = {lowGreen("32")} + {lowGreen("0")}
                 + {lowGreen("8")} + {lowGreen("0")} + {lowGreen("2")} + {lowGreen("1")} = {lowGreen("43")}.</li>
            </ol>
          </p>
          <h1 className='strike-through-text text-m' id="conversion-decimal-to-binary-id">Conversion from Binary to Decimal</h1>
          <p>
            Suppose we want to convert the decimal number {lowGreen("25")} to binary:
            <ol>
              <li>Divide {lowGreen("25")} by {lowGreen("2")}: quotient = {lowGreen("12")}, remainder = {lowGreen("1")}.</li>
              <li>Divide {lowGreen("12")} by {lowGreen("2")}: quotient = {lowGreen("6")}, remainder = {lowGreen("0")}.</li>
              <li>Divide {lowGreen("6")} by {lowGreen("2")}: quotient = {lowGreen("3")}, remainder = {lowGreen("0")}.</li>
              <li>Divide {lowGreen("3")} by {lowGreen("2")}: quotient = {lowGreen("1")}, remainder = {lowGreen("1")}.</li>
              <li>Divide {lowGreen("1")} by {lowGreen("2")}: quotient = {lowGreen("0")}, remainder = {lowGreen("1")}.</li>
              <li>The quotients have now reached {lowGreen("1010")}, so we stop the division.</li>
              <li>Read the remainders obtained in reverse order: {lowGreen("11001")}.</li>
            </ol>
          </p>
          <h1 className='strike-through-text text-m' id="binary-operations-id">Binary Operations</h1>
          <p>
            As Binary is another number system, it can do the arithmic operations a deca system can: addition, subtraction, multiplication and division.<br />
            Let's go over each one separately:
          </p>
          <p>
            {red("Addition of Binary Number:")}
            <p>
              To add binary numbers, you can follow a similar process to adding decimal numbers, but using the rules of binary addition. Here's a step-by-step guide:<br /><br />

              <ol>
                <li>
                  Write down the binary numbers you want to add, aligning them vertically with the rightmost digits aligned.
                </li>
                <li>
                  Start from the rightmost digits and add the corresponding bits. Here are the rules for adding each pair of bits:<br /><br />
                  <table style={{ borderCollapse: 'collapse' }}>
                    <tr>
                      <td style={{ padding: '5px', border: '1px solid white' }}>0 + 0</td>
                      <td style={{ padding: '5px', border: '1px solid white' }}>=</td>
                      <td style={{ padding: '5px', border: '1px solid white' }}>0</td>
                    </tr>
                    <tr>
                      <td style={{ padding: '5px', border: '1px solid white' }}>0 + 1</td>
                      <td style={{ padding: '5px', border: '1px solid white' }}>=</td>
                      <td style={{ padding: '5px', border: '1px solid white' }}>1</td>
                    </tr>
                    <tr>
                      <td style={{ padding: '5px', border: '1px solid white' }}>1 + 0</td>
                      <td style={{ padding: '5px', border: '1px solid white' }}>=</td>
                      <td style={{ padding: '5px', border: '1px solid white' }}>1</td>
                    </tr>
                    <tr>
                      <td style={{ padding: '5px', border: '1px solid white' }}>1 + 1</td>
                      <td style={{ padding: '5px', border: '1px solid white' }}>=</td>
                      <td style={{ padding: '5px', border: '1px solid white' }}>0 (with a carry of 1)</td>
                    </tr>
                  </table>
                  <br />
                </li>

                <li>
                  Write down the sum of the bits you obtained under the column of the addition. <br />
                  If there is a carry (a value of 1) from the addition of the previous column, add it to the next column's addition.
                </li>
                <li>
                  Repeat steps 2-4 for each column, moving from right to left.<br />
                  If there is a carry after adding the leftmost column, write it as an additional digit at the leftmost side of the sum.
                </li>
              </ol>

            </p>
            {red("Subtraction of Binary Number:")}
            <p>
              To subtract binary numbers, you can follow a similar process to subtracting decimal numbers, but using the rules of binary addition. Here's a step-by-step guide:<br /><br />
              <ol>
                <li>
                  Write down the binary numbers you want to subtract, aligning them vertically with the rightmost digits aligned.
                </li>
                <li>
                  Start from the rightmost digits and subtract the corresponding bits. Here are the rules for adding each pair of bits:<br /><br />
                  <table style={{ borderCollapse: 'collapse' }}>
                    <tr>
                      <td style={{ padding: '5px', border: '1px solid white' }}>1</td>
                      <td style={{ padding: '5px', border: '1px solid white' }}>-</td>
                      <td style={{ padding: '5px', border: '1px solid white' }}>1</td>
                      <td style={{ padding: '5px', border: '1px solid white' }}>=</td>
                      <td style={{ padding: '5px', border: '1px solid white' }}>0</td>
                    </tr>
                    <tr>
                      <td style={{ padding: '5px', border: '1px solid white' }}>1</td>
                      <td style={{ padding: '5px', border: '1px solid white' }}>-</td>
                      <td style={{ padding: '5px', border: '1px solid white' }}>0</td>
                      <td style={{ padding: '5px', border: '1px solid white' }}>=</td>
                      <td style={{ padding: '5px', border: '1px solid white' }}>1</td>
                    </tr>
                    <tr>
                      <td style={{ padding: '5px', border: '1px solid white' }}>0</td>
                      <td style={{ padding: '5px', border: '1px solid white' }}>-</td>
                      <td style={{ padding: '5px', border: '1px solid white' }}>1</td>
                      <td style={{ padding: '5px', border: '1px solid white' }}>=</td>
                      <td style={{ padding: '5px', border: '1px solid white' }}>1 (with a borrow of 1)</td>
                    </tr>
                    <tr>
                      <td style={{ padding: '5px', border: '1px solid white' }}>1</td>
                      <td style={{ padding: '5px', border: '1px solid white' }}>-</td>
                      <td style={{ padding: '5px', border: '1px solid white' }}>1</td>
                      <td style={{ padding: '5px', border: '1px solid white' }}>=</td>
                      <td style={{ padding: '5px', border: '1px solid white' }}>0</td>
                    </tr>
                  </table>
                  <br />
                </li>

                <li>
                  Write down the difference of the bits you obtained under the column of the subtraction. <br />
                  If there is a carry (a value of {lowGreen("1")}) from the subtraction of the previous column, add it to the next column's subtraction.
                </li>
                <li>
                  Repeat steps 2-4 for each column, moving from right to left.<br />
                  If there is a carry after adding the leftmost column, add it to the least significant bit
                </li>
              </ol>
            </p>
            {red("Multiplication of Binary Number:")}
            <p>
              To multiply binary numbers, you can follow a similar process to multiply decimal numbers, but using the rules of binary multiplication. Here's a step-by-step guide:<br /><br />
              <ol>
                <li>
                  Write down the binary numbers you want to multiply, aligning them vertically with the rightmost digits aligned.
                </li>
                <li>
                  Each number from the multiplier has to be multiplied with the multiplicand.<br />
                  Start from the rightmost digits and multiply the corresponding bits. Here are the rules for adding each pair of bits:<br /><br />
                  <table style={{ borderCollapse: 'collapse' }}>
                    <tr>
                      <td style={{ padding: '5px', border: '1px solid white' }}>0</td>
                      <td style={{ padding: '5px', border: '1px solid white' }}>x</td>
                      <td style={{ padding: '5px', border: '1px solid white' }}>0</td>
                      <td style={{ padding: '5px', border: '1px solid white' }}>=</td>
                      <td style={{ padding: '5px', border: '1px solid white' }}>0</td>
                    </tr>
                    <tr>
                      <td style={{ padding: '5px', border: '1px solid white' }}>0</td>
                      <td style={{ padding: '5px', border: '1px solid white' }}>x</td>
                      <td style={{ padding: '5px', border: '1px solid white' }}>1</td>
                      <td style={{ padding: '5px', border: '1px solid white' }}>=</td>
                      <td style={{ padding: '5px', border: '1px solid white' }}>0</td>
                    </tr>
                    <tr>
                      <td style={{ padding: '5px', border: '1px solid white' }}>1</td>
                      <td style={{ padding: '5px', border: '1px solid white' }}>x</td>
                      <td style={{ padding: '5px', border: '1px solid white' }}>0</td>
                      <td style={{ padding: '5px', border: '1px solid white' }}>=</td>
                      <td style={{ padding: '5px', border: '1px solid white' }}>0</td>
                    </tr>
                    <tr>
                      <td style={{ padding: '5px', border: '1px solid white' }}>1</td>
                      <td style={{ padding: '5px', border: '1px solid white' }}>x</td>
                      <td style={{ padding: '5px', border: '1px solid white' }}>1</td>
                      <td style={{ padding: '5px', border: '1px solid white' }}>=</td>
                      <td style={{ padding: '5px', border: '1px solid white' }}>1</td>
                    </tr>
                  </table>
                  <br />
                </li>

                <li>
                  Write down the product of the bits you obtained under the column of the multiplication. <br />
                  If there is a carry (a value of 1) from the multiplication of the previous column, add it to the next column's addition once you multiplied.
                </li>
                <li>
                  Repeat steps 2-4 for each column, moving from right to left.<br />
                  If there is a carry after adding the leftmost column, add it to the least significant bit when adding results after multiplication
                </li>
              </ol>
            </p>
          </p>
          {red("Division of Binary Number:")}
          <p>
            To divide binary numbers, it is similar to the decimal way of dividing. Here's a step-by-step guide:<br /><br />
            <ul>
              <li>
                Start with the dividend and divisor: Identify the binary numbers you want to divide. The dividend is the number you want to divide, and the divisor is the number you are dividing by.
              </li>
              <li>
                Set up the division: Write the dividend and divisor in the division format, with the dividend on the left and the divisor on the right. If the dividend is shorter than the divisor, you can add leading zeros to the dividend.
              </li>
              <li>
                Perform the division: Starting from the leftmost digit of the dividend, perform the following steps until you reach the rightmost digit:
                <ol type="a">
                  <li>
                    Divide: Take the leftmost digits of the dividend that match the length of the divisor. Divide these digits by the divisor. The result will be either 0 or 1.
                  </li>
                  <li>
                    Write the quotient: Write the quotient (result of the division) above the dividend digits you used in the previous step. If the division result is 0, write 0 as the quotient. If the division result is 1, write 1 as the quotient.
                  </li>
                  <li>
                    Multiply: Multiply the divisor by the quotient (either 0 or 1) obtained in the previous step. Write the product below the dividend digits used for division.
                  </li>
                  <li>
                    Subtract: Subtract the product obtained in the previous step from the dividend digits you used for division. Write the result below the product.
                  </li>
                  <li>
                    Bring down: Bring down the next digit of the dividend to the right of the result obtained from subtraction.
                  </li>
                </ol>
              </li>
              <li>
                Repeat the steps: Repeat steps 3a to 3e until you have processed all the digits of the dividend.
              </li>
              <li>
                Interpret the result: The final result will be the quotient obtained from the division. If there is a remainder after the last subtraction step, it can also be written as part of the final result.
              </li>
              <li>
                Here are the rules of divisions:
                <br /><br />
                <table style={{ borderCollapse: 'collapse' }}>
                  <tr>
                    <th style={{ padding: '5px', border: '1px solid white' }}>Dividend</th>
                    <th style={{ padding: '5px', border: '1px solid white' }}>Divisor</th>
                    <th style={{ padding: '5px', border: '1px solid white' }}>Quotient</th>
                    <th style={{ padding: '5px', border: '1px solid white' }}>Remainder</th>
                  </tr>
                  <tr>
                    <td style={{ padding: '5px', border: '1px solid white' }}>0</td>
                    <td style={{ padding: '5px', border: '1px solid white' }}>Any non-zero divisor</td>
                    <td style={{ padding: '5px', border: '1px solid white' }}>0</td>
                    <td style={{ padding: '5px', border: '1px solid white' }}>0</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '5px', border: '1px solid white' }}>1</td>
                    <td style={{ padding: '5px', border: '1px solid white' }}>Any divisor</td>
                    <td style={{ padding: '5px', border: '1px solid white' }}>1</td>
                    <td style={{ padding: '5px', border: '1px solid white' }}>0</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '5px', border: '1px solid white' }}>Dividend is less than divisor</td>
                    <td style={{ padding: '5px', border: '1px solid white' }}>Any divisor</td>
                    <td style={{ padding: '5px', border: '1px solid white' }}>0</td>
                    <td style={{ padding: '5px', border: '1px solid white' }}>Dividend</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '5px', border: '1px solid white' }}>Dividend is equal to divisor</td>
                    <td style={{ padding: '5px', border: '1px solid white' }}>Any divisor</td>
                    <td style={{ padding: '5px', border: '1px solid white' }}>1</td>
                    <td style={{ padding: '5px', border: '1px solid white' }}>0</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '5px', border: '1px solid white' }}>Dividend is greater than divisor</td>
                    <td style={{ padding: '5px', border: '1px solid white' }}>Any divisor</td>
                    <td style={{ padding: '5px', border: '1px solid white' }}>Quotient obtained through division</td>
                    <td style={{ padding: '5px', border: '1px solid white' }}>Remainder obtained through subtraction</td>
                  </tr>
                </table>
              </li>
            </ul>

            <br />
          </p>
          <h1 className='strike-through-text text-m' id="binary-logic-id">Binary Logic</h1>
          <p>
            Binary logic is fundamental in digital electronics and computer science, and it involves performing logical operations on binary numbers or individual bits within those numbers. Here are the commonly used logical operations in binary logic:
            <ul>
              <li>
                AND (Conjunction): The logical AND operation returns a result of {lowGreen("1")} only if both inputs are {lowGreen("1")}. Otherwise, it returns {lowGreen("0")}. It can be represented using the "&" symbol
              </li>
              <li>
                OR (Disjunction):
                The logical OR operation returns a result of {lowGreen("1")} if at least one of the inputs is {lowGreen("1")}. Otherwise, it returns {lowGreen("0")}. It can be represented using the "|" symbol.
              </li>
              <li>
                XOR (Exclusive OR):
                The logical XOR operation returns a result of {lowGreen("1")} if the inputs are different (one {lowGreen("0")} and one {lowGreen("1")}). Otherwise, it returns {lowGreen("0")}. It can be represented using the "^" symbol.
              </li>
              <li>
                NOT (Negation):
                The logical NOT operation (also called inversion) flips the input bit. It returns the opposite value, where {lowGreen("1")} becomes {lowGreen("0")}, and {lowGreen("0")} becomes {lowGreen("1")}. It can be represented using the "~" symbol.
              </li>
            </ul>
            These logical operations form the building blocks of digital circuits, Boolean algebra, and logical reasoning in computer science. They are used to manipulate and combine binary values, make decisions, control flow, and perform various calculations in digital systems.
          </p>
          <h1 className='strike-through-text text-m' id="data-storage-id">Data Storage</h1>
          <p>
            Data storage capacity is measured in units such as kilobytes (KB), megabytes (MB), gigabytes (GB), terabytes (TB), and so on. These units represent progressively larger powers of {lowGreen("2")}. For example, 1 kilobyte is equivalent to {lowGreen("2")}^{lowGreen("10")} bytes ({lowGreen("1,024")} bytes), {lowGreen("1")} megabyte is equivalent to {lowGreen("2")}^{lowGreen("20")} bytes ({lowGreen("1,048,576")} bytes), and so on. The storage capacity of modern computer systems has increased significantly over time, with terabytes and petabytes becoming common.
          </p>
          {red("Technologies")}
          <p>
            There are various data storage technologies employed in computer systems, including hard disk drives (HDDs), solid-state drives (SSDs), magnetic tape drives, optical discs (CDs, DVDs, Blu-ray), and cloud-based storage systems. Each technology has its own characteristics in terms of capacity, speed, durability, and cost, making them suitable for different use cases.
          </p>
          {red("Data Persistence")}
          <p>
            Data storage also involves the concept of data persistence, which refers to the ability of stored data to retain its content even when the power is turned off. Persistent storage mediums, such as hard drives and solid-state drives, allow data to be stored and accessed even after the system is shut down and restarted.
          </p>
          Effective data storage is crucial for computer systems to store programs, files, databases, and other types of information. It enables data processing, retrieval, sharing, and long-term preservation. Advances in data storage technologies continue to drive innovations and improvements in computing capabilities and applications.
          <h1 className='strike-through-text text-m' id="hexadecimal-system-id">Hexadecimal System</h1>
          <p>
            Hexadecimal representation is widely used in computer systems as a more convenient and compact way to express binary numbers.
          </p>
          <p>
            We have gathered until now that the Decimal system that we use everyday, is power of {lowGreen("10")}, the binary system is power of 2, while the Hexadecimal
            system is power of {lowGreen("16")}.
            <br />
            It is same as the decimal system, except there are {lowGreen("6")} more symbols attached to it.
          </p>
          <p>
            Hexadecimal is a base {lowGreen("16")} number system, meaning it uses {lowGreen("16")} distinct symbols to represent values. The symbols used in hexadecimal are digits
            from {lowGreen("0")} to {lowGreen("9")} and letters from A to F, where A represents {lowGreen("10")}, B represents {lowGreen("11")}, and so on up to F representing {lowGreen("15")}.
          </p>
          <p>
            Since hexadecimal is a base {lowGreen("16")} system and binary is a base {lowGreen("2")} system, there is a direct relationship between them. Each hexadecimal digit
            corresponds to a group of four binary digits (bits). This relationship allows binary numbers to be represented in a more concise and manageable form.
          </p>
          <p>
            Hexadecimal representation offers a more compact representation compared to binary. Since each hexadecimal digit represents four bits, it is easier to read and write hexadecimal numbers, especially when dealing with larger binary values. For example, the binary number 110010111010 can be represented as 1A7A in hexadecimal, making it more manageable and concise.
          </p>
          <p>
            Hexadecimal representation is widely used in various computing applications. It is commonly used in low-level programming, such as machine code, assembly language, and memory addresses. Hexadecimal is also used to represent color values in web design and graphics, where each color channel (red, green, blue) is represented by a hexadecimal digit.
            <br />
            Overall, hexadecimal representation provides a more convenient and concise way to express binary numbers. Its compactness and simplicity make it a preferred
            choice in many computer-related fields, simplifying tasks like memory addressing, bitwise operations, and color representation.
          </p>
          <h1 className='strike-through-text text-m' id="conversion-binary-to-hexadecimal-id">Conversion from Binary to Hexadecimal</h1>
          <p>
            To convert a binary number to hexadecimal, follow these steps:
            <ol>
              <li>
                Group the binary digits into sets of four, starting from the rightmost side. If the number of digits is not a multiple of four, add leading zeros to create complete sets of four.
              </li>
              <li>
                Assign a hexadecimal digit to each group of four bits based on the following mapping:<br /><br />
                <table style={{ borderCollapse: 'collapse' }}>
                  <tr>
                    <td style={{ padding: '5px', border: '1px solid white' }}>0</td>
                    <td style={{ padding: '5px', border: '1px solid white' }}>0000</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '5px', border: '1px solid white' }}>1</td>
                    <td style={{ padding: '5px', border: '1px solid white' }}>0001</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '5px', border: '1px solid white' }}>2</td>
                    <td style={{ padding: '5px', border: '1px solid white' }}>0010</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '5px', border: '1px solid white' }}>3</td>
                    <td style={{ padding: '5px', border: '1px solid white' }}>0011</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '5px', border: '1px solid white' }}>4</td>
                    <td style={{ padding: '5px', border: '1px solid white' }}>0100</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '5px', border: '1px solid white' }}>5</td>
                    <td style={{ padding: '5px', border: '1px solid white' }}>0101</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '5px', border: '1px solid white' }}>6</td>
                    <td style={{ padding: '5px', border: '1px solid white' }}>0110</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '5px', border: '1px solid white' }}>7</td>
                    <td style={{ padding: '5px', border: '1px solid white' }}>0111</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '5px', border: '1px solid white' }}>8</td>
                    <td style={{ padding: '5px', border: '1px solid white' }}>1000</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '5px', border: '1px solid white' }}>9</td>
                    <td style={{ padding: '5px', border: '1px solid white' }}>1001</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '5px', border: '1px solid white' }}>A</td>
                    <td style={{ padding: '5px', border: '1px solid white' }}>1010</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '5px', border: '1px solid white' }}>B</td>
                    <td style={{ padding: '5px', border: '1px solid white' }}>1011</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '5px', border: '1px solid white' }}>C</td>
                    <td style={{ padding: '5px', border: '1px solid white' }}>1100</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '5px', border: '1px solid white' }}>D</td>
                    <td style={{ padding: '5px', border: '1px solid white' }}>1101</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '5px', border: '1px solid white' }}>E</td>
                    <td style={{ padding: '5px', border: '1px solid white' }}>1110</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '5px', border: '1px solid white' }}>F</td>
                    <td style={{ padding: '5px', border: '1px solid white' }}>1111</td>
                  </tr>
                </table>
                <br />
              </li>
              <li>
                Replace each group of four bits with its corresponding hexadecimal digit.
              </li>
              <li>
                Concatenate the hexadecimal digits together to get the final hexadecimal representation.
              </li>
            </ol>
            <p>
              Let's say we have the binary number: {lowGreen("11011010")}. We group it in sets of four meaning we split in half.<br /><br />
              The outcome should be: {lowGreen("1101")} {lowGreen("1010")}. If we look at the table mapping, this equal to D A.<br />
              Therefore, the binary number {lowGreen("11011010")} is equivalent to the hexadecimal number DA.
            </p>
          </p>
          <h1 className='strike-through-text text-m' id="conversion-hexadecimal-to-binary-id">Conversion from Hexadecimal to Binary</h1>
          <p>
            To convert a hexadecimal number to binary, follow these steps:
            <ol>
              <li>
                Group the binary digits into sets of four, starting from the rightmost side. If the number of digits is not a multiple of four, add leading zeros to create complete sets of four.
              </li>
              <li>
                Assign a hexadecimal digit to each group of four bits based on the following mapping:<br /><br />
                <table style={{ borderCollapse: 'collapse' }}>
                  <tr>
                    <th style={{ padding: '5px', border: '1px solid white' }}>Hexadecimal</th>
                    <th style={{ padding: '5px', border: '1px solid white' }}>Binary</th>
                  </tr>
                  <tr>
                    <td style={{ padding: '5px', border: '1px solid white' }}>0</td>
                    <td style={{ padding: '5px', border: '1px solid white' }}>0000</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '5px', border: '1px solid white' }}>1</td>
                    <td style={{ padding: '5px', border: '1px solid white' }}>0001</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '5px', border: '1px solid white' }}>2</td>
                    <td style={{ padding: '5px', border: '1px solid white' }}>0010</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '5px', border: '1px solid white' }}>3</td>
                    <td style={{ padding: '5px', border: '1px solid white' }}>0011</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '5px', border: '1px solid white' }}>4</td>
                    <td style={{ padding: '5px', border: '1px solid white' }}>0100</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '5px', border: '1px solid white' }}>5</td>
                    <td style={{ padding: '5px', border: '1px solid white' }}>0101</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '5px', border: '1px solid white' }}>6</td>
                    <td style={{ padding: '5px', border: '1px solid white' }}>0110</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '5px', border: '1px solid white' }}>7</td>
                    <td style={{ padding: '5px', border: '1px solid white' }}>0111</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '5px', border: '1px solid white' }}>8</td>
                    <td style={{ padding: '5px', border: '1px solid white' }}>1000</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '5px', border: '1px solid white' }}>9</td>
                    <td style={{ padding: '5px', border: '1px solid white' }}>1001</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '5px', border: '1px solid white' }}>A</td>
                    <td style={{ padding: '5px', border: '1px solid white' }}>1010</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '5px', border: '1px solid white' }}>B</td>
                    <td style={{ padding: '5px', border: '1px solid white' }}>1011</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '5px', border: '1px solid white' }}>C</td>
                    <td style={{ padding: '5px', border: '1px solid white' }}>1100</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '5px', border: '1px solid white' }}>D</td>
                    <td style={{ padding: '5px', border: '1px solid white' }}>1101</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '5px', border: '1px solid white' }}>E</td>
                    <td style={{ padding: '5px', border: '1px solid white' }}>1110</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '5px', border: '1px solid white' }}>F</td>
                    <td style={{ padding: '5px', border: '1px solid white' }}>1111</td>
                  </tr>
                </table>
                <br />
              </li>
              <li>
                Replace each hexadecimal digit with its corresponding binary representation.
              </li>
              <li>
                Combine the binary representations of each digit to obtain the binary equivalent of the given hexadecimal number.
              </li>
            </ol>
            <p>
              Let's convert the hexadecimal number "2F" to binary. If we look at the table this is equivalent to {lowGreen("2")}: {lowGreen("0010")} and F: {lowGreen("1111")}.<br />
              Therefore we have 2F as a final result.
            </p>
          </p>
          <h1 className='strike-through-text text-m' id="bitwise-id">Bitwise Operations</h1>
          <p>
            Bitwise operation are a powerful tool for every programmer, because they allow you to manipulate individual bits within a value.
          </p>
          <p>
            Why would you use bitwise operations? <br />
            - Because they allow greater precision and require fewer resources, bitwise operators can make some code faster and more efficient. Examples of uses of bitwise operations
            include encryption, compression, graphics, communications over ports/sockets, embedded systems programming and finite state machines.
          </p>
          <p>
            Here are the main bitwise operations:
            <ul>
              <li>
                {red("Bitwise AND (&):")}
                The bitwise AND operation compares the corresponding bits of two numbers and returns a new number where each bit is set
                to {lowGreen("1")} only if both bits were {lowGreen("1")} in the original numbers. Otherwise, the bit is set to {lowGreen("0")}.
              </li><br />
              <li>
                {red("Bitwise OR (|):")}
                The bitwise OR operation compares the corresponding bits of two numbers and returns a new number
                where each bit is set to {lowGreen("1")} if at least one of the bits was {lowGreen("1")} in the original numbers.
              </li><br />
              <li>
                {red("Bitwise XOR (^):")}
                The bitwise XOR operation compares the corresponding bits of two numbers and returns a new number where each bit is set to {lowGreen("1")} if the bits differ between the original numbers.
                In other words, it returns {lowGreen("1")} if the bits are different and {lowGreen("0")} if the bits are the same.
              </li><br />
              <li>
                {red("Bitwise NOT (~):")}
                The bitwise NOT operation, also known as one's complement, inverts each bit of a number. It flips {lowGreen("0")}s to {lowGreen("1")}s and {lowGreen("1")}s to {lowGreen("0")}s. This operation is usually performed on individual bits,
                but it can also be applied to the entire binary representation of a number.
              </li><br />
              <li>
                {red("Left Shift (<<):")}
                The left shift operation shifts the bits of a number to the left by a specified number of positions. This effectively multiplies the number by {lowGreen("2")}
                raised to the power of the shift amount. The bits shifted out on the left are lost, and the empty positions on the right are filled with zeros.
              </li><br />
              <li>
                {red("Right Shift (>>):")}
                The right shift operation shifts the bits of a number to the right by a specified number of positions. This effectively divides the number by {lowGreen("2")} raised to the power of the shift amount.
                The bits shifted out on the right are lost, and the empty positions on the left are filled based on the sign (for signed numbers) or with zeros (for unsigned numbers).
              </li><br />
            </ul>
          </p>
          <h1 className='strike-through-text text-m' id="data-types-id">Data Types</h1>
          <p>
            I think since we now know what Binary represents, it's worth noting each data type and their {red("bytes")} required and range.<br /><br />
            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
              <thead>
                <tr>
                  <th style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>Variable Type</th>
                  <th style={{  padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>Keyword</th>
                  <th style={{  padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>Bytes Required</th>
                  <th style={{  padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>Range</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>Character</td>
                  <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>char</td>
                  <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>1 byte</td>
                  <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>-128 to 127</td>
                </tr>
                <tr>
                  <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>Unsigned Character</td>
                  <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>unsigned char</td>
                  <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>1 byte</td>
                  <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>0 to 255</td>
                </tr>
                <tr>
                  <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>Integer</td>
                  <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>int</td>
                  <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>4 bytes</td>
                  <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>-2,147,483,648 to 2,147,483,647</td>
                </tr>
                <tr>
                  <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>Short Integer</td>
                  <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>short</td>
                  <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>2 bytes</td>
                  <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>-32,768 to 32,767</td>
                </tr>
                <tr>
                  <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>Long Integer</td>
                  <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>long</td>
                  <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>8 bytes</td>
                  <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>-9,223,372,036,854,775,808 to 9,223,372,036,854,775,807</td>
                </tr>
                <tr>
                  <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>Unsigned Integer</td>
                  <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>unsigned int</td>
                  <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>4 bytes</td>
                  <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>0 to 4,294,967,295</td>
                </tr>
                <tr>
                  <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>Unsigned Short Integer</td>
                  <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>unsigned short</td>
                  <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>2 bytes</td>
                  <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>0 to 65,535</td>
                </tr>
                <tr>
                  <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>Unsigned Long Integer</td>
                  <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>unsigned long</td>
                  <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>8 bytes</td>
                  <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>0 to 18,446,744,073,709,551,615</td>
                </tr>
                <tr>
                  <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>Float</td>
                  <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>float</td>
                  <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>4 bytes</td>
                  <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>±1.17549435 × 10^-38 to ±3.40282347 × 10^38</td>
                </tr>
                <tr>
                  <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>Double</td>
                  <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>double</td>
                  <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>8 bytes</td>
                  <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>±2.2250738585072014 × 10^-308 to ±1.7976931348623157 × 10^308</td>
                </tr>
                <tr>
                  <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>Long Double</td>
                  <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>long double</td>
                  <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>10 bytes</td>
                  <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>3.4E-4932 to 1.1E+4932</td>
                </tr>
              </tbody>
            </table>
          </p>
          <p>
            {red("Note: ")} {lowGreen("1")} byte is {lowGreen("8")} bits. Meaning that to calculate a range, we get the {red("bytes required")}, for example for {lowGreen("integer - 4")} the range would be {red("2 ^ (4 * 8)")}.<br/>
            <ul>
              <li>
              {lowGreen("2")} ^: because its binary, base {lowGreen("2")} system
              </li>
              <li>
              {lowGreen("4")}: because the bytes required is {lowGreen("4")} for integers
              </li>
              <li>
              {lowGreen("8")}: because {lowGreen("1")} byte is {lowGreen("8")} bits, so we convert {lowGreen("4")} to bits, resulting in {lowGreen("32")}.
              </li>
              </ul>
          </p>
        </>
    }
  ]
