import React, { useEffect, useState } from 'react'
import MenuItem from '../components/menu-item/MenuItem'
import "./Menu.css"
import { iconsForMenu } from '../icons/icons'
import InsideMenu from '../inside-menu/InsideMenu'

function Menu() {
  const [isOpenMenu, setIsOpen] = useState<boolean>(true);
  const [playAnim, setPlayAnim] = useState<boolean>(false);
  const [tab, setTab] = useState<String>("");

  function getOpenState(open : boolean, title : string) {
    setIsOpen(open)
    setTab(title)
  }

  useEffect(() => {
    setTimeout(() => {
      setPlayAnim(true);
    }, 5000);
  }, [])

  return (
    <div className='Menu align-center align-center-mobile'>
      {isOpenMenu && <div className='menu--flex'>
        {/* <MenuItem playAnim={playAnim} sendData={getOpenState} title='Tools' imageSource={iconsForMenu.tools}/> */}
        <MenuItem playAnim={playAnim} sendData={getOpenState} title='Snippets' imageSource={iconsForMenu.snippet}/>
        <MenuItem playAnim={playAnim} sendData={getOpenState} title='Libraries' imageSource={iconsForMenu.libraries}/>
        <MenuItem playAnim={playAnim} sendData={getOpenState} title='Knowledge' imageSource={iconsForMenu.knowledge}/>
        <MenuItem playAnim={playAnim} sendData={getOpenState} title='About' imageSource={iconsForMenu.about}/>
      </div>}
      <InsideMenu tab={tab} isOpen={isOpenMenu} sendData={getOpenState}/>
    </div>
  )
}

export default Menu