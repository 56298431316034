import React from 'react'
import './ConnectedLine.css'

interface ConnectedLineProps {
    year: string,
    title: string,
}

function ConnectedLine(props : ConnectedLineProps) {
  return (
    <div className='ConnectedLine'>
        <div className='text-sm align-center w-a'>{props.title}</div>
        <div className='ConnectedLine-line align-center mx-3'></div>
        <div className='text-xs align-center t-r w-a'>{props.year}</div>
    </div>
  )
}

export default ConnectedLine