import React from 'react'
import "./List.css"

interface ListProps {
    text: string,
}

function List(props : ListProps) {
  return (
    <li className='List text-sm'>{props.text}</li>
  )
}

export default List