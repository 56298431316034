const blue = (element : string) => {
    return <span style={{color: 'rgb(79, 193, 255)'}}>{element}</span>
}
const yellow = (element : string) => {
    return <span style={{color: 'rgb(220, 220, 170)'}}>{element}</span>
}
const pink = (element : string) => {
    return <span style={{color: 'rgb(196, 133, 191)'}}>{element}</span>
}
const green = (element : string) => {
    return <span style={{color: 'rgb(77, 201, 176)'}}>{element}</span>
}
export const snippets : Array<{
    title: string;
    description: string;
    snippet: any
  }> =  [
    {
        title: 'Find distance between two points',
        description: 'Calculate the length of a line segment between two points in Euclidean space',
        snippet: 
        <div style={{color: 'white'}}>
            In Mathematics, Euclidean space is the fundamental space of geometry. It can be three-dimensional space and two-dimensional known as Euclidean plane, but in modern mathematics
            there are Euclidean spaces of any positive integer dimension.<br/>
            It uses the Cartesian coordinate system which specifies that each point is uniquely identified by a pair of numerical coordinates. Each line is called an axis and the point where
            they meet is called the origin.
            <br/><br/>
            <strong style={{color: "red"}}>One Dimension:</strong><br/>
            The distance between two points in one dimensional space is their absolute difference.<br/><br/>
            {blue('const ')} {yellow('findDistanceBetween2Points1D')} {blue('=')} {blue('(')} a, b {blue(')')} {blue('=>')} {blue('{')}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;{pink('return')} Math.{yellow('abs')}{blue('(')}a - b{blue(')')} <br/>
            {blue('}')}<br/><br/>
            <strong style={{color: "red"}}>Two Dimension:</strong><br/>
            The distance between two points in two dimensional space is calculated by the distance formula.<br/><br/>
            {blue('const ')} {yellow('findDistanceBetween2Points2D')} {blue('=')} {blue('(')} a, b {blue(')')} {blue('=>')} {blue('{')}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;{pink('return')} Math.{yellow('sqrt')}{blue('(')}Math.{yellow('pow')}{blue('(')}b.x1 - a.x1, 2{blue(')')} + {blue('(')}Math.{yellow('pow')}{blue('(')}b.y1 - a.y1, 2{blue('))')} <br/>
            {blue('}')}<br/><br/>
            or<br/><br/>
            In this function we are using the exponentiation operator added in ECMA2016, which is a shorthand for the <i>Math.{blue('pow')}</i> function <br/>
            {blue('const ')} {yellow('findDistanceBetween2Points2D')} {blue('=')} {blue('(')} a, b {blue(')')} {blue('=>')} {blue('{')}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;{pink('return')} Math.{yellow('sqrt')}{blue('(')}{blue('(')}b.x1 - a.x1{blue(')')} {blue('**')} 2 + {blue('(')}b.y1 - a.y1{blue(')')} {blue('**')} 2{blue(')')} <br/>
            {blue('}')}<br/><br/>
            <strong style={{color: "red"}}>Three Dimension or higher:</strong><br/>
            The distance between two points in three or higher dimensional space is same as the two-dimensional formula except the first parameter or first point (a) comes first whereas
            in the previous example (b) came first.<br/><br/>
            {blue('const ')} {yellow('findDistanceBetween2Points2D')} {blue('=')} {blue('(')} a, b {blue(')')} {blue('=>')} {blue('{')}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;{pink('return')} Math.{yellow('sqrt')}{blue('(')}Math.{yellow('pow')}{blue('(')}a.x1 - b.x1, 2{blue(')')} + {blue('(')}Math.{yellow('pow')}{blue('(')}a.y1 - b.y1, 2{blue(')')} + {blue('(')}Math.{yellow('pow')}{blue('(')}a.z1 - b.z1, 2{blue('))')} <br/>
            {blue('}')}<br/><br/>
            <strong style={{color: "red"}}>Using Math native built-in library:</strong><br/>
            Distance can be calculated from the cartesian coordinates of the points using Pythagorean theorem, therefore occasionaly being called the Pythagorean distance.
            Using <i>Math.{yellow('hypot')}</i> function returns the square root of the sum of squares of its arguments. Which saves you time writing the formula yourself.<br/><br/>
            Math.{yellow('hypot')}{blue('(')}b.x1 - a.x1, b.y1, a.y1{blue(')')}<br/>
        </div>
    },
    {
        title: 'Custom Event Listener',
        description: 'Create a custom javascript event listener',
        snippet: 
        <div style={{color: "white"}}>
            We are going to create a virtual button with a click listener as an example.
            <br/><br/>
            {blue('class')} {green('VirtualButton')} {pink('{')}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;{blue('#listeners')} = {blue('new')} {green('Map')} {blue('()')};
            <br/><br/>
            &nbsp;&nbsp;&nbsp;&nbsp;{blue('constructor() {')}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue('this.listeners')}.{yellow('set(')} "click" , {pink('[ ]')}{yellow(')')};<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;{blue('}')}
            <br/><br/>
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow('addEventListener')} {blue('(event, listener) {')}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue('this.listener')}.{yellow('get(')}{blue('event')}{yellow(')')}.{yellow('push(')}{blue('listener')}{yellow(')')};<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;{blue('}')}
            <br/><br/>
            &nbsp;&nbsp;&nbsp;&nbsp;{yellow('click')}{blue('() {')}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue('const listeners')} = {blue('this.listeners')}.{yellow('get(')}"click"{yellow(")")};<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{blue('const event')} = {blue('new')} {green("Event")}{yellow("()")};<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("for")} {yellow("(")}{blue("let listener of listeners")}{yellow(") {")}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{yellow("listener")}{pink("(")}{blue("event")}{pink(")")};<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("if (")}{blue("event")}.{yellow("stopPropagation()")}{pink(") {")}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("break")};<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{pink("}")}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{yellow("}")}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;{blue("}")}<br/>
            {pink("}")}<br/><br/>
            VirtualButton class has been created which contains two functions, depending on the goal we want to achieve.<br/><br/>
            The first function, {yellow("addEventListener")} is for setting up which event listener we would like to get notified for, while the second functions {yellow("click")} is just the click function that triggers the listener to execute.
            <br/><br/>
            The order is the following:<br/>
            <ol>
                <li>Initializing a new map which will contain all listeners for our virtual button</li>
                <li>On calling the virtual button class we set up the click listener with no callback using a constructor</li>
                <li>We create a function that adds the listener to the current button instance and change the default click listener with no callback to the one we send as a parameter</li>
                <li>To be notified when the listener is triggered, we create a click function because we are doing a custom click event listener. If we have setup an event listener before, by clicking we
                    find the click listener from inside the listeners map, create a new Event object from the event constructor provided by JavaScript API and pass it inside the listener callback that we
                    have provided earlier through the {yellow("addEventListener")} function.
                </li>
                <li>Finally we break the for loop if the event has stopped propagation.</li>
            </ol>
            <details>
                <summary>{blue("new")} {green("Event")}{blue("()")}</summary>
                <p>
                    Use this constructor to create a new Event object that can be dispatched by a script. <br/>
                    Syntax: {blue("new")} {green("Event")}{yellow("(")}type, options - optional parameter{yellow(")")}
                </p>
            </details>
            <details>
                <summary>{blue("event")}.{yellow("stopPropagation")}{blue("()")}</summary>
                <p>
                    Prevents further propagation of the current event in the capturing and bubbling phase in other words it prevents any parent handlers from being executed.
                </p>
            </details>
        </div>
    }
]