import React, { useRef } from 'react'
import { useEffect } from 'react';
import "./PercentageBar.css"

interface PercentageBar {
    percentage: number,
    title: string,
}

function PercentageBar(props : PercentageBar) {
    const bar = useRef<HTMLDivElement>(null);
    const title = useRef<HTMLDivElement>(null);
    const percent = useRef<HTMLDivElement>(null);
    const flexWidth = useRef<HTMLDivElement>(null);
    
    useEffect(() => {
        if (bar.current && flexWidth.current) {
            bar.current.style.width = '0%';
            percent.current!.style.opacity = '0';
            title.current!.style.opacity = '0';
            flexWidth.current.style.width = props.percentage + "%";
            setTimeout(() => {
                bar.current!.style.transition = 'all 1.5s ease-in'
                title.current!.style.opacity = '1';
                percent.current!.style.opacity = '1';
                percent.current!.style.transition = 'all 1s 1.8s ease-in';
                bar.current!.style.width = props.percentage + "%";
            }, 2000)
        }
    })  
  return (
    <div className='PercentageBar'>
        <div ref={flexWidth} className='PercentageBar-titleAndPercentage'>
            <div ref={title} className='text-sm'>{props.title}</div>
            <div ref={percent} className='text-xs align-bottom'>{props.percentage}%</div>
        </div>
        <div ref={bar} className='PercentageBar-bar'></div>
    </div>
  )
}

export default PercentageBar