import { title } from 'process'
import React, { useEffect } from 'react'
import About from '../components/about-tab/About'
import Knowledge from '../components/knowledge-tab/Knowledge'
import Library from '../components/library-tab/Library'
import Snippets from '../components/snippets-tab/Snippets'
import "./InsideMenu.css"

interface InsideMenuInterface {
    isOpen: boolean,
    sendData: Function
    tab: String
}

function InsideMenu(props : InsideMenuInterface) {
    function sendDataToParent() {
        props.sendData(true)
    }
    return (
            <>
                {!props.isOpen && 
                <div className="InsideMenu">
                    <button className='BackButton'><span className='PropsTab' onClick={() => sendDataToParent()}><span>&#8612;</span> {props.tab}</span></button>
                    {props.tab === "About" && <About/>}
                    {props.tab === "Knowledge" && <Knowledge/>}
                    {props.tab === "Snippets" && <Snippets/>}
                    {props.tab === "Libraries" && <Library/>}
                </div>
                }
            </>
        )
}

export default InsideMenu