import React from 'react'
import {Link} from 'react-router-dom'
import "./Library.css"
import { libraries } from './LibraryArray'

function Library() {
  return (
    <div className='Library'>
    {libraries.map(item => {
        const {title, link, description} = item
        return (
            <>
            <div className='Library_box' onClick={() => window.open(link, "_blank")}>
              <div className="Library_title">{title}</div>
              <div className='Library_description'>{description}</div>
            </div>
            </>
        );
    })}
</div>
  )
}

export default Library