import React, { ReactNode, useRef } from 'react'
import "./MenuItem.css"

interface MenuItemProps {
  title: string,
  imageSource: ReactNode,
  sendData: Function,
  playAnim: boolean,
}

function MenuItem(props : MenuItemProps) {
  function sendDataToParent() {
    props.sendData(false, props.title)
}
const Menuitem = useRef<HTMLDivElement>(null);
  return (
    <div ref={Menuitem} onClick={() => {sendDataToParent()}} className={props.playAnim ? 'MenuItem m-3 opacity-1 menu-item-fly' : 'MenuItem m-3 menu-item-animation'}>
      <div className='MenuItem-icon'>
        <div className='MenuItem-svg'>
        {props.imageSource}
        </div>
      </div>
      <div className='MenuItem-title'>
        {props.title}
      </div>
    </div>
  )
}

export default MenuItem